import { Col, Row, Select, Space, Typography } from "antd";
import { useMemo, useState } from "react";
import { metricColumns } from "../dashboardColumns";
import { QueryResponse } from "../types";
import { ComboChart } from "./ComboChart";
import { LineChart } from "./LineChart";

const { Text } = Typography;

interface DashboardChartProps {
  data?: QueryResponse;
  isLoading: boolean;
  rowLimitError: boolean;
}

export const DashboardChart: React.FC<DashboardChartProps> = (props) => {
  const [seriesAmount, setSeriesAmount] = useState(10);
  const [selectedMetric, setSelectedMetric] = useState("total_revenue_net");

  const chartType = useMemo(() => {
    if (props.data === undefined) {
      return undefined;
    } else if (
      props.data.dimensions.length === 2 &&
      props.data.dimensions.includes("date") &&
      props.data.time_granularity !== "total"
    ) {
      return "line";
    } else if (
      props.data.dimensions.length === 1 ||
      (props.data.dimensions.length === 2 &&
        props.data.time_granularity === "total")
    ) {
      return "combo";
    } else {
      return undefined;
    }
  }, [props.data]);

  const chart = useMemo(() => {
    return chartType === "combo" ? (
      <ComboChart seriesAmount={seriesAmount} data={props.data!} />
    ) : (
      <LineChart
        seriesAmount={seriesAmount}
        data={props.data!}
        selectedMetric={selectedMetric}
      />
    );
  }, [seriesAmount, selectedMetric, props.data, chartType]);

  return props.rowLimitError ? (
    <div>Chart is not supported after exceeding the rows limit.</div>
  ) : chartType === undefined ? (
    <div>Chart is not supported for selected grouping.</div>
  ) : (
    <Space style={{ width: "100%" }} direction="vertical">
      <Row justify={"end"}>
        <Space>
          {chartType === "line" && (
            <Col>
              <Row>
                <Text type="secondary">Selected metric:</Text>
              </Row>
              <Row>
                <Select
                  style={{ width: "175px" }}
                  value={selectedMetric}
                  onChange={setSelectedMetric}
                  options={metricColumns.map((obj) => ({
                    value: obj.dataIndex,
                    label: obj.title,
                  }))}
                />
              </Row>
            </Col>
          )}
          {!(
            chartType === "combo" &&
            props.data?.dimensions.length === 1 &&
            props.data?.dimensions.includes("date")
          ) && (
            <Col>
              <Row>
                <Text type="secondary">Top:</Text>
              </Row>
              <Row>
                <Select
                  value={seriesAmount}
                  onChange={setSeriesAmount}
                  options={[{ value: "10" }, { value: "20" }, { value: "30" }]}
                />
              </Row>
            </Col>
          )}
        </Space>
      </Row>
      {chart}
    </Space>
  );
};
