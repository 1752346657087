import { SmileOutlined } from "@ant-design/icons";
import { Button, Col, Menu, Result, Row, Space } from "antd";
import React, { useContext } from "react";

import {
  DashboardOutlined,
  HeartOutlined,
  IdcardOutlined,
  TabletOutlined,
} from "@ant-design/icons";
import { Header } from "antd/es/layout/layout";
import axios from "axios";
import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { MainContext } from "./Main";
import AccountDetails from "./accounts/AccountDetails";
import Accounts from "./accounts/Accounts";
import AppDetails from "./apps/AppDetails";
import Apps from "./apps/Apps";
import Dashboard from "./dashboard/Dashboard";
import EstimationsDashboard from "./dashboard/estimations/EstimationsDashboard";
import { Health } from "./health/Health";
import { Normalization } from "./normalization/Normalization";
import Users from "./users/Users";
import { User } from "./users/types";
import { BoomBitLogo } from "./utils/BoomBitLogo";
import { GoogleLogin } from "./utils/GoogleLogin";
import { UserButton } from "./utils/UserButton";

export interface NavigationProps {
  user: User | undefined;
}

const RequireAuth = () => {
  const { user } = useContext(MainContext);
  const location = useLocation();
  if (!user) {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Link to={`/login?redirectTo=${location.pathname}`}>
            <Button type="primary">Log in</Button>
          </Link>
        }
      />
    );
  }
  return <Outlet />;
};

const Logout = () => {
  const { user, setUser } = useContext(MainContext);
  if (user) {
    axios.get("/api/auth/logout").then(() => setUser(undefined));
  }
  return <Navigate to="/login" />;
};

const GetCurrentRoute = () => {
  const location = useLocation();
  return location.pathname.split("/")[1].split("/")[0];
};

const Navigation: React.FC<NavigationProps> = (props) => {
  return (
    <>
      <Header className="header">
        <Row>
          <Col style={{ flexShrink: 1, flexBasis: "10rem" }}>
            <Space className="header-logo">
              <BoomBitLogo />
              FinDash
            </Space>
          </Col>
          <Col style={{ flexShrink: 2, flexBasis: "45%" }}>
            <Menu
              mode="horizontal"
              selectedKeys={[GetCurrentRoute()]}
              defaultSelectedKeys={["dashboard"]}
              items={
                props.user
                  ? [
                      {
                        label: <Link to="/dashboard">Dashboard</Link>,
                        key: "dashboard",
                        icon: <DashboardOutlined />,
                      },
                      {
                        label: <Link to="/apps">Apps</Link>,
                        key: "apps",
                        icon: <TabletOutlined />,
                      },
                      ...(props.user?.is_admin
                        ? [
                            {
                              label: <Link to="/accounts">Accounts</Link>,
                              key: "accounts",
                              icon: <IdcardOutlined />,
                            },
                            {
                              label: (
                                <Link to="/normalization">Normalization</Link>
                              ),
                              key: "normalization",
                              icon: <TabletOutlined />,
                            },
                            // {
                            //   label: <Link to="/users">Users</Link>,
                            //   key: "users",
                            //   icon: <TeamOutlined />,
                            // },
                            {
                              label: <Link to="/health">Health</Link>,
                              key: "health",
                              icon: <HeartOutlined />,
                            },
                            // {
                            //   label: <Link to="/import">Import</Link>,
                            //   key: "import",
                            //   icon: <ImportOutlined />,
                            // },
                          ]
                        : []),
                    ]
                  : []
              }
            />
          </Col>
          <Col flex={1}>
            <div></div>
          </Col>
          <Col style={{ flexShrink: 1, flexBasis: "10rem" }}>
            <Row justify={"end"}>
              {props.user && <UserButton user={props.user} />}
            </Row>
          </Col>
        </Row>
      </Header>
      <Routes>
        <Route
          path="/login"
          element={!props.user ? <GoogleLogin /> : <Outlet />}
        />
        <Route element={<RequireAuth />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/apps" element={<Apps />} />
          <Route path="/apps/:id" element={<AppDetails />} />
          <Route path="/accounts" element={<Accounts />} />
          <Route path="/accounts/:id" element={<AccountDetails />} />
          <Route path="/normalization" element={<Normalization />} />
          <Route
            path="/users"
            element={
              props.user?.is_admin ? (
                <Users />
              ) : (
                <Result
                  status="403"
                  title="403"
                  subTitle="Sorry, you are not authorized to access this page."
                  // extra={<Button type="primary">Back Home</Button>}
                />
              )
            }
          />
          <Route
            path="/health"
            element={
              props.user?.is_admin ? (
                <Health />
              ) : (
                <Result
                  status="403"
                  title="403"
                  subTitle="Sorry, you are not authorized to access this page."
                  // extra={<Button type="primary">Back Home</Button>}
                />
              )
            }
          />
          {/* <Route
            path="/import"
            element={
              props.user?.is_admin ? (
                <Import />
              ) : (
                <Result
                  status="403"
                  title="403"
                  subTitle="Sorry, you are not authorized to access this page."
                  // extra={<Button type="primary">Back Home</Button>}
                />
              )
            }
          /> */}
          <Route
            path="/dashboard/estimations"
            element={
              props.user?.is_admin ? (
                <EstimationsDashboard />
              ) : (
                <Result
                  status="403"
                  title="403"
                  subTitle="Sorry, you are not authorized to access this page."
                  // extra={<Button type="primary">Back Home</Button>}
                />
              )
            }
          />
          <Route path="/logout" element={<Logout />} />
        </Route>
        <Route
          path="/"
          element={
            <Result
              icon={<SmileOutlined />}
              title="Hello"
              extra={
                props.user ? (
                  <Navigate to="/apps" replace={true} />
                ) : (
                  <Link to="/login">
                    <Button type="primary">Login</Button>
                  </Link>
                )
              }
            />
          }
        />
        <Route
          path="*"
          element={
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
            />
          }
        />
      </Routes>
    </>
  );
};

export default Navigation;
