import { Form, FormInstance, message, Modal } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";

export const ModalFormContext = React.createContext<FormInstance<any> | null>(
  null
);

export interface FormProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  onSuccess?: (data?: any) => void;
  onFail?: () => void;
  newRecordExtraData?: { [key: string]: string | number };
  apiEndpoint: string;
  onSave?: () => void;
  onCancel?: () => void;
  data?: any;
  entityLabel: string;
  widthPx?: number;
}

interface ModalFormProps extends FormProps {
  children: React.ReactNode;
}

export const ModalForm: React.FC<ModalFormProps> = (props) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const isEditing = props.data !== undefined;

  useEffect(() => {
    if (props.isOpen) {
      if (props.data !== undefined) {
        form.setFieldsValue(props.data);
      } else {
        form.resetFields();
      }
    }
  }, [props.data, props.isOpen, form]);

  const onSave = (formData: any) => {
    console.log(formData);
    setIsLoading(true);
    axios({
      method: isEditing ? "patch" : "post",
      url:
        props.apiEndpoint +
        (isEditing ? `/${props.data.id || props.data.key}` : ""),
      data:
        !isEditing && props.newRecordExtraData
          ? { ...formData, ...props.newRecordExtraData }
          : formData,
    })
      .then((response: any) => {
        console.log(response);
        message.success("Success");
        props.setIsOpen(false);
        props.onSuccess?.(response.data);
      })
      .catch((error: any) => {
        console.log(error);
        console.log(error.response);
        if (error.response && error.response.data.detail) {
          const msg = error.response.data.detail;
          if (typeof msg === "string") message.error(`Error: ${msg}`);
          else
            message.error(
              `Error: ${JSON.stringify(error.response.data.detail[0])}`
            );
        } else message.error("Error");
        props.onFail?.();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onCancel = () => {
    if (props.onCancel) props.onCancel();
    props.setIsOpen(false);
  };

  return (
    <Modal
      open={props.isOpen}
      title={`${isEditing ? "Edit" : "New"} ${props.entityLabel || ""}`}
      okText={isEditing ? "Save" : "Add"}
      cancelText="Cancel"
      onCancel={onCancel}
      confirmLoading={isLoading}
      destroyOnClose={true}
      closable={!isLoading}
      getContainer={false}
      maskClosable={!isLoading}
      cancelButtonProps={{ disabled: isLoading }}
      width={props.widthPx || 800}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onSave(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        preserve={false}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        autoComplete="off"
      >
        <ModalFormContext.Provider value={form}>
          {props.children}
        </ModalFormContext.Provider>
      </Form>
    </Modal>
  );
};
