import { Avatar, Button, Dropdown, Space } from "antd";
import { debounce } from "lodash";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { User } from "../users/types";
import { ApiTokensModal } from "./ApiTokensModal";

interface UserButtonProps {
  user: User;
}

export const UserButton: React.FC<UserButtonProps> = (props) => {
  const [btnHover, setBtnHover] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const updateBtnHover = useMemo(
    () => debounce((hover: boolean) => setBtnHover(hover), 500),
    []
  );

  return (
    <div>
      <Dropdown
        menu={{
          onMouseEnter: () => {
            setBtnHover(true);
            updateBtnHover(true);
          },
          onMouseLeave: () => updateBtnHover(false),
          items: [
            {
              label: <Link to="/logout">Logout</Link>,
              key: "logout",
            },
            ...(props.user.is_admin
              ? [
                  {
                    label: "API Tokens",
                    key: "api_tokens",
                    onClick: () => {
                      setIsModalOpen(true);
                    },
                  },
                ]
              : []),
          ],
        }}
      >
        <Button
          className={
            btnHover ? "header__user-button__hover" : "header__user-button"
          }
          onMouseEnter={() => {
            setBtnHover(true);
            updateBtnHover(true);
          }}
          onMouseLeave={() => updateBtnHover(false)}
          type="text"
        >
          <Space size="middle">
            <Avatar src={props.user.avatar_url} size="small" />
            {btnHover && props.user.name}
          </Space>
        </Button>
      </Dropdown>
      <ApiTokensModal
        onCancel={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
      />
    </div>
  );
};
