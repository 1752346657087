import { DownloadOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Space, Tooltip, message } from "antd";
import dayjs from "dayjs";
import { useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { MainContext } from "../Main";
import { fetchJobs } from "../health/api";
import { handleApiError } from "../utils/ApiErrorHandler";
import { Page } from "../utils/Page";
import { PageTitle } from "../utils/PageTitle";
import { ExchangeRatesModal } from "./ExchangeRatesModal";
import PlacementsAssigned from "./PlacementsAssigned";
import PlacementsUnassigned from "./PlacementsUnassigned";
import { postForceRefresh } from "./api";
import { Placement } from "./types";

export const Normalization: React.FC = () => {
  const { user } = useContext(MainContext);
  const queryClient = useQueryClient();
  const forceRefreshMutation = useMutation({
    mutationFn: () => postForceRefresh(),
    onError: (error) => {
      error && handleApiError(error);
    },
    onSuccess: () => {
      message.success(
        "Created a job for transfering tables. Changes should be visible within 20 minutes."
      );
      queryClient.invalidateQueries("latest_transfer_job");
    },
  });
  const [isForceRefreshDisabled, setIsForceRefreshDisabled] = useState(false);
  const transferJob = useQuery("latest_transfer_job", {
    queryFn: () =>
      fetchJobs().then((data) => {
        const filtered = data.filter(
          (job) => job.dag_id === "revenue_transfers"
        );
        let job = filtered.filter((job) => {
          const date = dayjs(job.created_at);
          const maxDate = dayjs(
            Math.max(...filtered.map((x) => dayjs(x.created_at).valueOf()))
          );
          return (
            date.format("YYYY-MM-DD HH:MM:ss") ===
            maxDate.format("YYYY-MM-DD HH:MM:ss")
          );
        });
        return job.length > 0 ? job[0] : undefined;
      }),
    refetchInterval: 5000,
    onSuccess: async (job) => {
      if (job && job.status === "processing") {
        setIsForceRefreshDisabled(true);
      } else {
        setIsForceRefreshDisabled(false);
      }
    },
  });

  const [activeTab, setActiveTab] = useState<"unassigned" | "assigned">(
    "unassigned"
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const PlacementsComponent =
    activeTab === "unassigned" ? PlacementsUnassigned : PlacementsAssigned;

  return (
    <Page>
      <PageTitle
        title="Normalization"
        breadcrumbs={[
          {
            label: "Normalization",
          },
        ]}
        extra={[
          user?.is_admin && (
            <Space key="1">
              <Button
                icon={<DownloadOutlined />}
                onClick={() => setIsModalOpen(true)}
              >
                Exchange rates
              </Button>
              <Tooltip
                // title={
                //   isForceRefreshDisabled
                //     ? "You need to wait for previous data refresh job to end."
                //     : "Forces transfers between tables to create new placements from recently imported data or to see newly assigned placements in dashboard."
                // }
                title={"Disabled in demonstration mode"}
              >
                <Button
                  // disabled={
                  //   isForceRefreshDisabled ||
                  //   transferJob.isFetching ||
                  //   transferJob.isLoading
                  // }
                  disabled
                  loading={isForceRefreshDisabled}
                  icon={<ReloadOutlined />}
                  onClick={() => forceRefreshMutation.mutate()}
                >
                  Force refresh
                </Button>
              </Tooltip>
            </Space>
          ),
        ]}
      />
      <Card
        tabList={[
          { tab: "Unassigned Placements", key: "unassigned" },
          { tab: "Assigned Placements", key: "assigned" },
        ]}
        onTabChange={(activeKey) =>
          setActiveTab(activeKey as "assigned" | "unassigned")
        }
      >
        <PlacementsComponent />
      </Card>
      <ExchangeRatesModal
        isOpen={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      />
    </Page>
  );
};

export const getPlacementKey = (placement: Placement) =>
  `${placement.id}_${placement.platform}_${placement.provider_id}_${placement.account.id}`;
