import axios from "axios";
import dayjs from "dayjs";
import fileDownload from "js-file-download";
import { FetchDataQuery, Preset, PresetPayload, QueryResponse } from "./types";

export const fetchDashboardData = (
  payload: FetchDataQuery
): Promise<QueryResponse | undefined> =>
  axios.post("/api/v1/dashboard/query", payload).then((response) => {
    if (payload.format === "csv") {
      fileDownload(
        response.data,
        `dashboard_${dayjs().format("YYYY-MM-DD_HH:mm")}.csv`
      );
    } else {
      return response.data;
    }
  });

export const fetchPresets = (): Promise<Preset[]> =>
  axios.get("/api/v1/dashboard/presets").then((response) => response.data);

export const patchPreset = (
  presetId: string,
  payload: PresetPayload
): Promise<Preset> =>
  axios
    .patch(`/api/v1/dashboard/presets/${presetId}`, payload)
    .then((response) => response.data);

export const postPreset = (payload: PresetPayload): Promise<Preset> =>
  axios
    .post("/api/v1/dashboard/presets", payload)
    .then((response) => response.data);

export const deletePreset = (presetId: string) =>
  axios.delete(`/api/v1/dashboard/presets/${presetId}`);
