import { AutoComplete, Form, Input } from "antd";
import { useQuery } from "react-query";
import { FormProps, ModalForm } from "../utils/ModalForm";
import { fetchAutocompleteValues } from "../utils/api";

interface EditAppModalProps extends FormProps {}

export const EditAppModal: React.FC<EditAppModalProps> = (props) => {
  const appCategorySuggestions = useQuery(
    ["app_category", "autocomplete"],
    () => fetchAutocompleteValues("app_category"),
    { enabled: false }
  );
  const teamSuggestions = useQuery(
    ["team", "autocomplete"],
    () => fetchAutocompleteValues("team"),
    { enabled: false }
  );
  const publisherSuggestions = useQuery(
    ["publisher", "autocomplete"],
    () => fetchAutocompleteValues("publisher"),
    { enabled: false }
  );
  const atomSuggestions = useQuery(
    ["atom", "autocomplete"],
    () => fetchAutocompleteValues("atom"),
    { enabled: false }
  );
  const bankAccountSuggestion = useQuery(
    ["bank_accounts", "autocomplete"],
    () => fetchAutocompleteValues("bank_account"),
    { enabled: false }
  );

  return (
    <ModalForm {...props}>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please provide name of app" }]}
        initialValue={props.data.name}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Category"
        name="category"
        rules={[
          {
            required: true,
            message: "Please provide category of app",
          },
        ]}
        initialValue={props.data.category}
      >
        <AutoComplete
          options={appCategorySuggestions.data}
          onDropdownVisibleChange={() =>
            appCategorySuggestions.data === undefined &&
            appCategorySuggestions.refetch()
          }
        />
      </Form.Item>
      <Form.Item
        label="Facebook Payout"
        name="facebook_payout"
        initialValue={props.data.facebook_payout}
      >
        <AutoComplete
          options={bankAccountSuggestion.data}
          onDropdownVisibleChange={() =>
            bankAccountSuggestion.data === undefined &&
            bankAccountSuggestion.refetch()
          }
        />
      </Form.Item>
      <Form.Item
        label="RevShare"
        name="revshare"
        initialValue={props.data.revshare}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Jira Atom" name="atom" initialValue={props.data.atom}>
        <AutoComplete
          options={atomSuggestions.data}
          onDropdownVisibleChange={() =>
            atomSuggestions.data === undefined && atomSuggestions.refetch()
          }
        />
      </Form.Item>
      <Form.Item label="Team" name="team" initialValue={props.data.team}>
        <AutoComplete
          options={teamSuggestions.data}
          onDropdownVisibleChange={() =>
            teamSuggestions.data === undefined && teamSuggestions.refetch()
          }
        />
      </Form.Item>
      <Form.Item
        label="Publisher"
        name="publisher"
        initialValue={props.data.publisher}
      >
        <AutoComplete
          options={publisherSuggestions.data}
          onDropdownVisibleChange={() =>
            publisherSuggestions.data === undefined &&
            publisherSuggestions.refetch()
          }
        />
      </Form.Item>
      {/* <Form.Item>
        <Button type="primary" icon={<SaveOutlined />} htmlType="submit">
          Save
        </Button>
      </Form.Item> */}
    </ModalForm>
  );
};
