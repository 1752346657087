import { message, Modal, Select, Space, Table } from "antd";
import { useState } from "react";
import { useMutation } from "react-query";
import { App } from "../apps/types";
import { handleApiError } from "../utils/ApiErrorHandler";
import { assignPlacements } from "./api";
import { Placement } from "./types";

const { Option } = Select;

interface AssignmentModalProps {
  isOpen: boolean;
  setIsOpen: (visible: boolean) => void;
  data: Placement[];
  apps: App[];
  onSuccess?: (data: Placement[]) => void;
  onFail?: () => void;
}

const AssignmentModal: React.FC<AssignmentModalProps> = (props) => {
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const placementsMutation = useMutation({
    mutationFn: () =>
      assignPlacements({
        platform_id: selectedPlatform,
        placements: props.data.map((placement) => ({
          id: placement.id,
          platform: placement.platform,
          provider_id: placement.provider_id,
        })),
      }),
    onError: (error) => {
      error && handleApiError(error);
    },
    onSuccess: (response) => {
      message.success("Success");
      props.setIsOpen(false);
      props.onSuccess?.(response.data);
    },
  });

  return (
    <Modal
      open={props.isOpen}
      title="Assign placements"
      okText={"Assign"}
      cancelText="Cancel"
      onCancel={() => {
        setSelectedPlatform("");
        props.setIsOpen(false);
      }}
      confirmLoading={placementsMutation.isLoading}
      destroyOnClose={true}
      closable={!placementsMutation.isLoading}
      getContainer={false}
      maskClosable={!placementsMutation.isLoading}
      cancelButtonProps={{ disabled: placementsMutation.isLoading }}
      okButtonProps={{ disabled: selectedPlatform === "" }}
      width={800}
      onOk={() => placementsMutation.mutate()}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Table
          size="small"
          dataSource={props.data}
          rowKey={(obj) => `${obj.id}${obj.provider_id}${obj.platform}`}
          columns={[
            {
              title: "Provider",
              dataIndex: "provider_id",
            },
            {
              title: "ID",
              dataIndex: "id",
            },
            {
              title: "OS",
              dataIndex: "platform",
            },
            {
              title: "Description",
              dataIndex: "description",
            },
          ]}
          pagination={{
            defaultPageSize: 10,
            position: ["bottomRight", "topRight"],
          }}
        />
        <Select
          placeholder="Select platform"
          style={{ width: "100%" }}
          onChange={setSelectedPlatform}
          showSearch={true}
          filterOption={(inputValue, option) =>
            option?.children
              ?.toString()
              .toLocaleLowerCase()
              .includes(inputValue.toLocaleLowerCase()) || false
          }
          // options={props.apps.map((app) => {
          //   return app.platforms.map((platform) => ({
          //     value: platform.id,
          //     label: `${app.name} - ${platform.name} - ${
          //       platform.bundle_id || platform.store_id
          //     }`,
          //   }));
          // })}
        >
          {props.apps.map((app) => {
            return app.platforms.map((platform) => {
              return (
                <Option key={platform.id} value={platform.id}>
                  {`${app.name} - ${platform.name} - ${
                    platform.bundle_id || platform.store_id
                  }`}
                </Option>
              );
            });
          })}
        </Select>
      </Space>
    </Modal>
  );
};

export default AssignmentModal;
