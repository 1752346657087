import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Modal,
  Progress,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import dayjs from "dayjs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleApiError } from "./ApiErrorHandler";
import { SpinWrapper } from "./SpinWrapper";
import { createApiToken, fetchApiTokens } from "./api";
import { ApiError } from "./types";

interface ApiTokensModalProps {
  isOpen: boolean;
  onCancel: () => void;
}

export const ApiTokensModal: React.FC<ApiTokensModalProps> = (props) => {
  const queryClient = useQueryClient();
  const apiTokens = useQuery("api_tokens", () => fetchApiTokens(), {
    onError: (error: ApiError) => {
      console.log(error);
    },
  });
  const createApiTokenMutation = useMutation({
    mutationFn: createApiToken,
    onError: (error) => {
      error && handleApiError(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("api_tokens");
      message.success("Success");
    },
  });

  return (
    <Modal
      title="API Tokens"
      onCancel={() => {
        props.onCancel();
      }}
      closable={!createApiTokenMutation.isLoading}
      open={props.isOpen}
      footer={null}
    >
      <SpinWrapper spinVisible={apiTokens.isLoading}>
        <Space direction="vertical">
          {apiTokens.data &&
            apiTokens.data.map((token, idx) => {
              return (
                <Card key={idx.toString()}>
                  <Typography.Paragraph>
                    <Typography.Text code copyable>
                      {token.api_token}
                    </Typography.Text>
                  </Typography.Paragraph>
                  <Row justify="space-between">
                    <Col>
                      <Row>
                        <Typography.Text type="secondary">
                          Expires in:
                        </Typography.Text>
                      </Row>
                      <Row>
                        {dayjs(token.expires_at).diff(dayjs(), "day")} Days
                      </Row>
                      <Row>
                        <Progress
                          strokeColor={"green"}
                          style={{ width: 100 }}
                          percent={
                            (dayjs(token.expires_at).diff(dayjs(), "day") /
                              dayjs(token.expires_at).diff(
                                dayjs(token.created_at),
                                "day"
                              )) *
                            100
                          }
                          size={"small"}
                          format={() => ""}
                        />
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <Typography.Text type="secondary">
                          Created at:
                        </Typography.Text>
                      </Row>
                      <Row>{dayjs(token.created_at).format("YYYY-MM-DD")}</Row>
                    </Col>
                  </Row>
                </Card>
              );
            })}
          <Row justify={"center"}>
            <Button
              loading={createApiTokenMutation.isLoading}
              disabled={createApiTokenMutation.isLoading}
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => createApiTokenMutation.mutate()}
            >
              Create new token
            </Button>
          </Row>
        </Space>
      </SpinWrapper>
    </Modal>
  );
};
