import { DatePicker, message, Modal } from "antd";
import { Dayjs, default as dayjs } from "dayjs";
import { useState } from "react";
import { useQuery } from "react-query";
import { fetchExchangeRates } from "./api";

interface exchangeRatesModalProps {
  isOpen: boolean;
  onCancel: () => void;
}

const { RangePicker } = DatePicker;

export const ExchangeRatesModal: React.FC<exchangeRatesModalProps> = (
  props
) => {
  const [dates, setDates] = useState([
    dayjs(new Date(2022, 5, 8)).subtract(7, "d"),
    dayjs(new Date(2022, 5, 8)),
  ]);
  const exchangeRates = useQuery(
    ["exchange_rates"],
    () =>
      fetchExchangeRates(
        dates[0].format("YYYY-MM-DD"),
        dates[1].format("YYYY-MM-DD")
      ),
    {
      onError: (error) => console.log(error),
      enabled: false,
      onSuccess: () => {
        message.success("Success");
        props.onCancel();
      },
    }
  );

  return (
    <Modal
      title="Download exchange rates"
      onCancel={() => {
        props.onCancel();
      }}
      closable={!exchangeRates.isLoading}
      open={props.isOpen}
      okButtonProps={{
        loading: exchangeRates.isFetching,
      }}
      cancelButtonProps={{
        disabled: exchangeRates.isFetching,
      }}
      onOk={() => {
        exchangeRates.refetch();
      }}
    >
      <RangePicker
        disabled={exchangeRates.isFetching}
        onChange={(e) => {
          e !== null && setDates([e[0] as Dayjs, e[1] as Dayjs]);
        }}
        value={[dates[0], dates[1]]}
        disabledDate={(current: Dayjs) =>
          current.year() > 2022 || current.year() < 2022
        }
        presets={[
          {
            label: "Today",
            value: [dayjs(new Date(2022, 5, 8)), dayjs(new Date(2022, 5, 8))],
          },
          {
            label: "Yesterday",
            value: [
              dayjs(new Date(2022, 5, 8)).subtract(1, "d"),
              dayjs(new Date(2022, 5, 8)).subtract(1, "d"),
            ],
          },
          {
            label: "Last 7 days",
            value: [
              dayjs(new Date(2022, 5, 8)).subtract(7, "d"),
              dayjs(new Date(2022, 5, 8)).subtract(1, "d"),
            ],
          },
          {
            label: "Last 30 days",
            value: [
              dayjs(new Date(2022, 5, 8)).subtract(30, "d"),
              dayjs(new Date(2022, 5, 8)).subtract(1, "d"),
            ],
          },
          {
            label: "Last Week",
            value: [
              dayjs(new Date(2022, 5, 8)).subtract(1, "w").startOf("week"),
              dayjs(new Date(2022, 5, 8)).subtract(1, "w").endOf("week"),
            ],
          },
          {
            label: "Last Month",
            value: [
              dayjs(new Date(2022, 5, 8)).subtract(1, "M").startOf("month"),
              dayjs(new Date(2022, 5, 8)).subtract(1, "M").endOf("month"),
            ],
          },
        ]}
      />
    </Modal>
  );
};
