import { Form, Input, Table, Tabs } from "antd";
import { useState } from "react";
import { getPlacementKey } from "../normalization/Normalization";
import { Placement } from "../normalization/types";
import { FormProps, ModalForm } from "../utils/ModalForm";
import { SpinWrapper } from "../utils/SpinWrapper";
import { App, Platform } from "./types";

interface PlatformFormProps extends FormProps {
  app: App;
  platform?: Platform;
}

export const PlatformForm: React.FC<PlatformFormProps> = (props) => {
  const [activetab, setActiveTab] = useState("platform");

  return (
    <ModalForm
      {...props}
      onCancel={() => {
        if (props.onCancel) {
          props.onCancel();
        }
        setActiveTab("platform");
      }}
    >
      <Tabs
        onChange={(activeKey) => setActiveTab(activeKey)}
        items={[
          {
            key: "platform",
            label: "Platform",
          },
          {
            key: "placements",
            label: "Placements",
          },
        ]}
      />
      {activetab === "platform" ? (
        <>
          <Form.Item
            initialValue={props.app.id as number}
            label="App Id"
            name="app_id"
            hidden
          >
            <Input />
          </Form.Item>
          <Form.Item
            initialValue={props.platform?.name}
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please provide name of app" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Store ID"
            name="store_id"
            initialValue={props.platform?.store_id}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Bundle ID"
            name="bundle_id"
            initialValue={props.platform?.bundle_id}
            rules={[{ required: true, message: "Please provide bundle ID" }]}
          >
            <Input />
          </Form.Item>
        </>
      ) : (
        <SpinWrapper spinVisible={props.platform?.placements === undefined}>
          <Table
            size="small"
            rowKey={(record) => getPlacementKey(record)}
            dataSource={props.platform?.placements}
            loading={props.platform?.placements === undefined}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              position: ["bottomRight", "topRight"],
            }}
            columns={[
              {
                title: "Provider",
                dataIndex: "provider_id",
                align: "left",
                sorter: {
                  compare: (a: Placement, b: Placement) =>
                    a.provider_id.localeCompare(b.provider_id),
                },
              },
              {
                title: "ID",
                dataIndex: "id",
                align: "left",
                sorter: {
                  compare: (a: Placement, b: Placement) =>
                    a.id.localeCompare(b.id),
                },
              },
              {
                title: "OS",
                dataIndex: "platform",
                align: "left",
                sorter: {
                  compare: (a: Placement, b: Placement) =>
                    a.platform.localeCompare(b.platform),
                },
              },
              {
                title: "Description",
                dataIndex: "description",
                align: "left",
                sorter: {
                  compare: (a: Placement, b: Placement) =>
                    (a.description || "").localeCompare(b.description || ""),
                },
              },
              {
                title: "Assigned by",
                dataIndex: "assigned_by",
                align: "left",
                sorter: {
                  compare: (a: Placement, b: Placement) =>
                    (a.assigned_by || "").localeCompare(b.assigned_by || ""),
                },
              },
            ]}
          />
        </SpinWrapper>
      )}
    </ModalForm>
  );
};
