import { Layout } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import "../styles.css";
import Navigation from "./Navigation";
import { User } from "./users/types";

interface MainContextProps {
  user: User | undefined;
  setUser: (user: User | undefined) => void;
}

export const MainContext = React.createContext<MainContextProps>(
  undefined as any
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
    },
  },
});

const Main: React.FC = () => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => getMe(), []);

  const getMe = () => {
    setIsLoading(true);
    axios
      .get("/api/auth/me")
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <BrowserRouter>
      <Layout style={{ minHeight: "100vh" }}>
        <MainContext.Provider
          value={{
            user: user,
            setUser: setUser,
          }}
        >
          <QueryClientProvider client={queryClient}>
            <Navigation user={user} />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </MainContext.Provider>
      </Layout>
    </BrowserRouter>
  );
};

export default Main;
