import { QueryFilters } from "../dashboard/types";

export const getProviderIconSmall = (provider_id: string) => {
  return (
    <img
      height={20}
      width={20}
      src={`https://bi-public-assets.s3.eu-central-1.amazonaws.com/af_partners/${provider_id}.png`}
    />
  );
};

export const getProviderIconBig = (provider_id: string) => {
  return (
    <img
      height={50}
      width={50}
      src={`https://bi-public-assets.s3.eu-central-1.amazonaws.com/af_partners/${provider_id}.png`}
    />
  );
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const filterObjectUndefinedValues = (obj: any) =>
  Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => value !== undefined)
  );

export const filterEmptyQueryFilters = (filters: QueryFilters) => {
  return Object.fromEntries(
    Object.entries(filters).filter(
      ([key, value]) => (value?.value || []).length > 0
    )
  );
};
