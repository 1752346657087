import { Space, Spin } from "antd";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { fetchAccounts, fetchProviders } from "../accounts/api";
import { fetchDashboardData } from "../dashboard/api";
import { DataRow } from "../dashboard/types";
import { Page } from "../utils/Page";
import { PageTitle } from "../utils/PageTitle";
import { ProviderDetailsDrawer } from "./ProviderDetailsDrawer";
import { ProviderTile } from "./ProviderTile";

export const Health: React.FC = () => {
  const allAccounts = useQuery("accounts", () => fetchAccounts(true, false));
  const providers = useQuery("providers", () => fetchProviders());
  const data = useQuery("healthData", () =>
    fetchDashboardData({
      // date_start: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
      // date_end: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
      date_start: "2022-06-01",
      date_end: "2022-06-08",
      filters: {},
      time_granularity: "daily",
      dimensions: [
        "date",
        "account_name",
        "account_id",
        "provider_id",
        "provider_name",
      ],
    })
  );
  const [selectedProvider, setSelectedProvider] = useState<
    string | undefined
  >();

  // const distProviders = useMemo(() => {
  //   if (allAccounts && allAccounts.data) {
  //     return Array.from(new Set(allAccounts.data.map((x) => x.provider_id)));
  //   }
  // }, [allAccounts.data]);

  const isProviderMissingData = (data: DataRow[]) => {
    if (data.length === 0) {
      return true;
    }
    for (const acc of new Set(data.map((_acc) => _acc.account_name))) {
      if (
        Array.from(
          new Set(
            data
              .filter((obj) => obj.account_name === acc)
              .map((row) => row.date)
          )
        ).length < 7
      ) {
        return true;
      }
    }
    return false;
  };

  const providerTiles = useMemo(() => {
    if (providers.data && data.data) {
      return providers.data?.map((provider) => (
        <ProviderTile
          providerName={provider.name}
          providerId={provider.id}
          onClick={() => {
            setSelectedProvider(provider.id);
          }}
          data={
            data.data?.data.filter((row) => row.provider_id === provider.id) ||
            []
          }
          isMissingData={isProviderMissingData(
            data.data?.data.filter((row) => row.provider_id === provider.id) ||
              []
          )}
        />
      ));
    }
  }, [data.data]);

  return (
    <Page>
      <PageTitle title="Health" breadcrumbs={[{ label: "Health" }]} />
      <ProviderDetailsDrawer
        isOpen={selectedProvider !== undefined}
        onClose={() => setSelectedProvider(undefined)}
        isMissingData={isProviderMissingData(
          data.data?.data.filter(
            (row) => row.provider_id === selectedProvider
          ) || []
        )}
        data={
          data.data?.data.filter(
            (row) => row.provider_id === selectedProvider
          ) || []
        }
        providerId={selectedProvider}
      />
      <Space style={{ width: "100%" }} direction="horizontal" wrap>
        {providerTiles ? providerTiles : <Spin />}
      </Space>
    </Page>
  );
};
