import { Space } from "antd";
import CheckableTag from "antd/es/tag/CheckableTag";
import { dimensionColumns } from "./dashboardColumns";
import { QueryParams, QueryParamsOptional } from "./types";

interface DimensionsPickerProps {
  queryParams: QueryParams;
  updateQueryParams: (newQueryParams: QueryParamsOptional) => void;
}

export const DimensionsPicker: React.FC<DimensionsPickerProps> = (props) => {
  return (
    <Space>
      <span>Group&nbsp;by:</span>
      <Space wrap size={0}>
        {dimensionColumns.map((col) => {
          return (
            <CheckableTag
              checked={props.queryParams.dimensions.includes(col.dataIndex)}
              key={col.dataIndex}
              onClick={() => {
                const value = props.queryParams.dimensions.includes(
                  col.dataIndex
                )
                  ? props.queryParams.dimensions.filter(
                      (dim) => dim !== col.dataIndex
                    )
                  : [...props.queryParams.dimensions, col.dataIndex];
                if (
                  value.includes("date") &&
                  props.queryParams.timeGranularity === "total"
                ) {
                  props.updateQueryParams({
                    dimensions: value,
                    timeGranularity: "daily",
                  });
                } else if (
                  !value.includes("date") &&
                  props.queryParams.timeGranularity !== "total"
                ) {
                  props.updateQueryParams({
                    dimensions: value,
                    timeGranularity: "total",
                  });
                } else if (
                  value.includes("app_name") &&
                  value.includes("platform") &&
                  !value.includes("bundle_id")
                ) {
                  props.updateQueryParams({
                    dimensions: [...value, "bundle_id"],
                  });
                } else {
                  props.updateQueryParams({
                    dimensions: value,
                  });
                }
              }}
            >
              {col.title}
            </CheckableTag>
          );
        })}
      </Space>
    </Space>
  );
};
