import { message } from "antd";
import { ApiError } from "./types";

export const handleApiError = (error: ApiError) => {
  console.log(error);
  console.log(error.response);
  if (error.response && error.response.data.detail) {
    const msg = error.response.data.detail;
    if (typeof msg === "string") message.error(`Error: ${msg}`);
    else
      message.error(`Error: ${JSON.stringify(error.response.data.detail[0])}`);
  } else message.error("Error");
};
