import {
  DeleteOutlined,
  FileAddOutlined,
  MoreOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Modal, Select, Space, message } from "antd";
import { AxiosError } from "axios";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deletePreset, fetchPresets, patchPreset, postPreset } from "../api";
import { Preset, QueryFilters, TimeGranularity } from "../types";
import { PresetNameModal2 } from "./PresetNameModal";

const { confirm } = Modal;

interface PresetsProps {
  setTimeGranularity: (timeGranularity: TimeGranularity) => void;
  setDimensions: (dimensions: string[]) => void;
  setFilters: (filters: QueryFilters) => void;
  setMetrics: (metrics: string[]) => void;
  queryValues: {
    timeGranularity: TimeGranularity;
    dimensions: string[];
    filters: QueryFilters;
  };
  metrics: string[];
}

export const Presets: React.FC<PresetsProps> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [presetSelected, setPresetSelected] = useState<Preset | undefined>();
  const queryClient = useQueryClient();
  const presets = useQuery("presets", fetchPresets);
  const patchPresetMutation = useMutation({
    mutationFn: () =>
      patchPreset(presetSelected!.id.toString(), {
        dimensions: props.queryValues.dimensions,
        filters: props.queryValues.filters,
        time_granularity: props.queryValues.timeGranularity,
        metrics: props.metrics,
      }),
    onSuccess: (response) => {
      queryClient.setQueryData("presets", [
        ...(presets.data || []).filter((obj) => obj.id !== response.id),
        {
          ...response,
          parameters: {
            ...response.parameters,
            timeGranularity: response.parameters.time_granularity,
          },
        },
      ]);
      message.success("Success");
    },
  });
  const postPresetMutation = useMutation({
    mutationFn: (name: string) =>
      postPreset({
        name: name,
        dimensions: props.queryValues.dimensions,
        filters: props.queryValues.filters,
        time_granularity: props.queryValues.timeGranularity,
        metrics: props.metrics,
      }),
    onError: (error: AxiosError) => {
      if (error) {
        if (error.response && error.response.status === 409) {
          message.error("Preset with such name already exists.");
        } else message.error("Error");
      }
    },
    onSuccess: (response) => {
      queryClient.setQueryData("presets", [...(presets.data || []), response]);
      message.success("Success", 1, () => setIsModalOpen(false));
      setPresetSelected(response);
    },
  });
  const deletePresetMutation = useMutation({
    mutationFn: () => deletePreset(presetSelected?.id.toString() || ""),
    onError: () => {},
    onSuccess: () => {
      queryClient.setQueryData(
        "presets",
        presets.data?.filter((obj) => obj.id !== presetSelected!.id)
      );
      message.success("Success");
      setPresetSelected(undefined);
    },
  });

  useEffect(() => {
    if (presetSelected) {
      presetSelected.parameters.time_granularity
        ? props.setTimeGranularity(presetSelected.parameters.time_granularity)
        : props.setTimeGranularity("daily");
      presetSelected.parameters.dimensions
        ? props.setDimensions(presetSelected.parameters.dimensions)
        : props.setDimensions([]);
      presetSelected.parameters.filters
        ? props.setFilters(presetSelected.parameters.filters)
        : props.setFilters({});
      presetSelected.parameters.metrics &&
        props.setMetrics(presetSelected.parameters.metrics);
    }
  }, [presetSelected]);

  return (
    <>
      <PresetNameModal2
        isOpen={isModalOpen}
        isLoading={postPresetMutation.isLoading}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        onOk={(presetName) => postPresetMutation.mutate(presetName)}
      />
      <Space>
        <Select
          style={{ width: "150px" }}
          placeholder="Select preset"
          options={
            presets.data &&
            presets.data.map((preset) => ({
              value: preset.id,
              label: preset.name,
            }))
          }
          onChange={(val) =>
            setPresetSelected(presets.data?.find((preset) => preset.id === val))
          }
          value={presetSelected ? presetSelected.id : undefined}
        />
        <Dropdown
          trigger={["click"]}
          menu={{
            items: [
              {
                key: "save_as",
                label: (
                  <span>
                    <FileAddOutlined />
                    &nbsp; Save as...
                  </span>
                ),
                onClick: () => setIsModalOpen(true),
              },
              ...(presetSelected
                ? [
                    {
                      key: "save",
                      label: (
                        <span>
                          <SaveOutlined />
                          &nbsp; Save
                        </span>
                      ),
                      disabled:
                        isEqual(
                          props.queryValues.dimensions,
                          presetSelected.parameters.dimensions
                        ) &&
                        isEqual(
                          props.queryValues.filters,
                          presetSelected.parameters.filters
                        ) &&
                        isEqual(
                          props.queryValues.timeGranularity,
                          presetSelected.parameters.time_granularity
                        ),
                      onClick: () => patchPresetMutation.mutate(),
                    },
                    {
                      key: "delete",
                      label: (
                        <span>
                          <DeleteOutlined />
                          &nbsp;Delete
                        </span>
                      ),
                      onClick: () => {
                        confirm({
                          title: presetSelected?.name,
                          icon: <DeleteOutlined />,
                          content: `Are you sure you want to delete this preset?`,
                          okText: "Delete",
                          onOk: () => deletePresetMutation.mutate(),
                        });
                      },
                    },
                  ]
                : []),
            ],
          }}
        >
          <Button type={"text"} icon={<MoreOutlined />} />
        </Dropdown>
      </Space>
    </>
  );
};
