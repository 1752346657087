import dayjs, { Dayjs } from "dayjs";

export const rangePickerPresets = [
  {
    label: "Today",
    value: [dayjs(new Date(2022, 5, 8)), dayjs(new Date(2022, 5, 8))],
  },
  {
    label: "Yesterday",
    value: [
      dayjs(new Date(2022, 5, 8)).subtract(1, "d"),
      dayjs(new Date(2022, 5, 8)).subtract(1, "d"),
    ],
  },
  {
    label: "Last 7 days",
    value: [
      dayjs(new Date(2022, 5, 8)).subtract(7, "d"),
      dayjs(new Date(2022, 5, 8)).subtract(1, "d"),
    ],
  },
  {
    label: "Last 30 days",
    value: [
      dayjs(new Date(2022, 5, 8)).subtract(30, "d"),
      dayjs(new Date(2022, 5, 8)).subtract(1, "d"),
    ],
  },
  {
    label: "This Week",
    value: [
      dayjs(new Date(2022, 5, 8)).startOf("week"),
      dayjs(new Date(2022, 5, 8)).endOf("week"),
    ],
  },
  {
    label: "This Month",
    value: [
      dayjs(new Date(2022, 5, 8)).startOf("month"),
      dayjs(new Date(2022, 5, 8)).endOf("month"),
    ],
  },
  {
    label: "Last Month",
    value: [
      dayjs(new Date(2022, 5, 8)).subtract(1, "M").startOf("month"),
      dayjs(new Date(2022, 5, 8)).subtract(1, "M").endOf("month"),
    ],
  },
  {
    label: "This Year",
    value: [
      dayjs(new Date(2022, 5, 8)).startOf("year"),
      dayjs(new Date(2022, 5, 8)).endOf("year"),
    ],
  },
] as { label: string; value: [Dayjs, Dayjs] }[];
