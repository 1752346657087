import { Pagination } from "antd";

interface DashboardTablePaginationProps {
  total?: number;
  current: number;
  setPage: (page: number) => void;
  setPageSize: (page: number) => void;
  pageSize: number;
}

export const DashboardTablePagination: React.FC<
  DashboardTablePaginationProps
> = (props) => {
  return (
    <Pagination
      total={props.total}
      showSizeChanger
      current={props.current}
      pageSize={props.pageSize}
      onChange={(page, pageSize) => {
        props.setPage(page);
        props.setPageSize(pageSize);
      }}
    />
  );
};
