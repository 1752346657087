import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Popover,
  Radio,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { fetchAutocompleteValues } from "../../utils/api";
import { Filter } from "../types";
import { FilterOptions } from "./FilterOptions";

const { Text, Paragraph } = Typography;

interface DashboardFilterSelectProps {
  label: string;
  index: string;
  filter: Filter;
  isVisible: boolean;
  onDelete: () => void;
  onChange: (newValue: Filter) => void;
  disabled: boolean;
}

export const DashboardFilterSelect: React.FC<DashboardFilterSelectProps> = (
  props
) => {
  const [filter, setFilter] = useState<Filter>(props.filter);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const suggestions = useQuery(
    [props.index, "autocomplete"],
    () => fetchAutocompleteValues(props.index),
    {
      onError: (error) => console.log(error),
      ...(props.filter.value.length === 0 && { enabled: false }),
    }
  );

  useEffect(() => {
    setFilter(props.filter);
  }, [props.filter]);

  const updateFilter = () => {
    if (
      filter.value.length === 0 ||
      filter.value.length === suggestions.data?.length
    ) {
      if (props.filter.value.length > 0) {
        props.onDelete();
      } else {
        setFilter({
          value: [],
          type: filter.type,
        });
      }
    } else {
      props.onChange(filter);
    }
  };

  return (
    <div>
      <Popover
        placement={"bottomLeft"}
        trigger={"click"}
        open={isPopoverOpen}
        onOpenChange={(newOpen: boolean) => {
          if (!suggestions.data && newOpen) {
            suggestions.refetch();
          }
          setIsPopoverOpen(newOpen);
          if (!newOpen) {
            updateFilter();
          }
        }}
        content={
          <Space
            direction="vertical"
            style={{ minWidth: "300px", width: "100%", padding: "8px 12px" }}
          >
            <Row justify={"space-between"}>
              <Radio.Group
                disabled={suggestions.isLoading}
                size="small"
                optionType="button"
                options={[
                  {
                    value: "include",
                    label: "Include",
                  },
                  {
                    value: "exclude",
                    label: "Exclude",
                  },
                ]}
                onChange={(value) =>
                  setFilter((prev) => ({
                    ...prev,
                    type: value.target.value,
                  }))
                }
                value={filter.type}
              />
              <Text style={{ whiteSpace: "nowrap" }} type="secondary">
                {filter.value.length} selected
              </Text>
            </Row>

            <FilterOptions
              index={props.index}
              isPopoverOpen={isPopoverOpen}
              isLoading={suggestions.isLoading}
              options={suggestions.data || []}
              selectedValues={filter.value || []}
              confirmedValues={props.filter.value}
              selectValue={(val: string) =>
                setFilter((prev) => ({
                  ...prev,
                  value: filter.value.includes(val)
                    ? filter.value.filter((obj) => obj !== val)
                    : [...filter.value, val],
                }))
              }
              selectAll={(val: boolean) =>
                suggestions.data &&
                setFilter((prev) => ({
                  ...prev,
                  value: val ? suggestions.data.map((obj) => obj.value) : [],
                }))
              }
            />
          </Space>
        }
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
            cursor: "pointer",
            padding: "8px 12px",
            ...(isPopoverOpen && { border: "1px solid #85bb65" }),
          }}
        >
          <Row justify={"space-between"}>
            <Space size={"small"}>
              <Text style={{ whiteSpace: "nowrap" }} type="secondary">
                {props.label}
              </Text>
              {suggestions.isLoading && props.filter.value.length > 0 ? (
                <LoadingOutlined />
              ) : (
                <>
                  <Text style={{ maxWidth: "200px" }} ellipsis>
                    {props.filter.type === "exclude" && " all except "}
                    {props.filter.value.length === 0
                      ? "All"
                      : props.index === "app_name"
                      ? suggestions.data &&
                        suggestions.data.find(
                          (obj) => obj.value === props.filter.value[0]
                        )!.label
                      : props.filter.value[0]}
                  </Text>
                  {props.filter.value.length > 1 && (
                    <Tooltip
                      title={
                        props.filter.value.length > 0 &&
                        suggestions.data &&
                        suggestions.data
                          ?.filter((obj) =>
                            (props.filter.value as string[]).includes(obj.value)
                          )
                          .map((obj) => obj.label)
                          .join(", ")
                      }
                    >
                      <Tag color="success">
                        +{props.filter.value.length - 1}
                      </Tag>
                    </Tooltip>
                  )}
                </>
              )}
            </Space>
            {props.filter.value.length > 0 && (
              <Button
                size="small"
                type="text"
                icon={<CloseOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onDelete();
                }}
                style={{
                  ...(props.filter.value.length === 1 && {
                    marginLeft: "16px",
                  }),
                }}
              />
            )}
          </Row>
        </Space>
      </Popover>
    </div>
  );
};
