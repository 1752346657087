import { ConfigProvider } from "antd";
import React from "react";
import ReactDOM from "react-dom/client";
import Main from "./components/Main";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#85BB65",
        },
      }}
    >
      <Main />
    </ConfigProvider>
  </React.StrictMode>
);
