import {
  DownloadOutlined,
  LineChartOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { Alert, Button, Card, Segmented, Space, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useQuery } from "react-query";
import { Page } from "../../utils/Page";
import { PageTitle } from "../../utils/PageTitle";
import { ApiError } from "../../utils/types";
import { metricsWithoutDollar } from "../Dashboard";
import { QueryFilters } from "../types";
import { Chart } from "./Chart";
import { ControlPanel } from "./ControlPanel";
import { EstimationsTable } from "./EstimationsTable";
import { fetchEstimatedData } from "./api";
import { metricColumns } from "./columns";

const { Text } = Typography;

const EstimationsDashboard: React.FC = () => {
  const data = useQuery(
    ["estimations_data"],
    () =>
      fetchEstimatedData({
        date_start: dateRange[0].format("YYYY-MM-DD"),
        date_end: dateRange[1].format("YYYY-MM-DD"),
        metric: selectedMetric!,
        filters: filters,
        ...(isBackInTimeEnabled && {
          back_in_time_days: verifyDays,
          training_days: trainingDays,
        }),
      }),
    {
      onError: (error: ApiError) => {
        if (error.response && error.response.data.detail) {
          const msg = error.response.data.detail;
          if (typeof msg === "string") setError(msg);
          else console.log(msg);
        } else console.log(error);
      },
    }
  );
  const [error, setError] = useState<undefined | string>();
  const [selectedVisualization, setSelectedVisualization] = useState("chart");
  const [selectedMetric, setSelectedMetric] =
    useState<string>("total_revenue_net");
  const [dateRange, setDateRange] = useState<dayjs.Dayjs[]>([
    dayjs().subtract(32, "days"),
    dayjs().add(5, "days"),
  ]);
  const [isBackInTimeEnabled, setIsBackInTimeEnabled] = useState(false);
  const [verifyDays, setVerifyDays] = useState<number>(7);
  const [trainingDays, setTrainingDays] = useState<number>(90);
  const [filters, setFilters] = useState<QueryFilters>({
    platform: {
      value: [],
      type: "include",
    },
    provider_id: {
      value: [],
      type: "include",
    },
    account_name: {
      value: [],
      type: "include",
    },
    bank_account: {
      value: [],
      type: "include",
    },
    app_category: {
      value: [],
      type: "include",
    },
  });

  const memoizedChart = useMemo(
    () => (
      <Chart
        estimationDateStart={data.data?.estimation_date_start}
        isLoading={data.isLoading || data.isFetching}
        data={data.data}
      />
    ),
    [data.data, data.isLoading, data.isFetching]
  );

  useEffect(() => {
    data.refetch();
  }, [selectedMetric]);

  return (
    <Page>
      <PageTitle
        title="Estimations"
        breadcrumbs={[{ label: "Estimations", route: undefined }]}
        align={"start"}
      />
      <Space style={{ width: "100%" }} direction="vertical">
        <ControlPanel
          currentData={data.data}
          isLoading={data.isLoading || data.isFetching}
          isBtnDisabled={
            dateRange.length === 2 && selectedMetric !== undefined
              ? false
              : true
          }
          verifyDays={verifyDays}
          trainingDays={trainingDays}
          filters={filters}
          dateRange={dateRange}
          selectedMetric={selectedMetric}
          setFilters={(newValues) =>
            setFilters((prevData) => ({ ...prevData, ...newValues }))
          }
          deleteFilter={(filter) => {
            const newFilters = { ...filters };
            delete newFilters[filter];
            setFilters(newFilters);
          }}
          addFilter={(filter) =>
            setFilters({
              ...filters,
              [filter]: { value: [], type: "include" },
            })
          }
          setVerifyDays={setVerifyDays}
          setTrainingDays={setTrainingDays}
          onBtnClick={() => {
            setError(undefined);
            data.refetch();
          }}
          setDateRange={setDateRange}
          setIsBackInTimeEnabled={setIsBackInTimeEnabled}
          isBackInTimeEnabled={isBackInTimeEnabled}
        />
        {error && (
          <>
            <Alert showIcon type="error" message={error} />
          </>
        )}
        <Card
          tabList={metricColumns.map((col) => ({
            key: col.dataIndex,
            tab: col.title,
          }))}
          onTabChange={setSelectedMetric}
          tabBarExtraContent={
            <Space>
              <CSVLink
                filename={`dashboard_estimations_${dayjs().format(
                  "YYYY-MM-DD_HH:mm"
                )}.`}
                data={(data.data?.estimations || []).map((row) => {
                  const digits = metricsWithoutDollar.includes(
                    data.data?.metric!
                  )
                    ? 0
                    : 2;
                  if (data.data?.back_in_time !== null) {
                    return {
                      date: row.date,
                      real_value: row.real_value?.toFixed(digits),
                      estimated_value: row.predicted_value?.toFixed(digits),
                    };
                  } else {
                    return {
                      date: row.date!,
                      [data.data.metric]:
                        row.date < data.data.estimation_date_start
                          ? row.real_value!.toFixed(digits)
                          : row.predicted_value!.toFixed(digits),
                      comment:
                        row.date < data.data.estimation_date_start
                          ? ""
                          : "estimated",
                    };
                  }
                })}
                className="btn btn-primary"
              >
                <Button type="text" icon={<DownloadOutlined />} />
              </CSVLink>
              <Segmented
                onChange={(val) => {
                  setSelectedVisualization(val as string);
                }}
                value={selectedVisualization}
                options={[
                  {
                    value: "chart",
                    label: <LineChartOutlined />,
                  },
                  {
                    value: "table",
                    label: <TableOutlined />,
                  },
                ]}
              />
            </Space>
          }
        >
          {selectedVisualization === "chart" ? (
            memoizedChart
          ) : selectedVisualization === "table" ? (
            <EstimationsTable
              data={data.data}
              isLoading={data.isLoading || data.isFetching}
              estimationDateStart={data.data?.estimation_date_start}
            />
          ) : undefined}
        </Card>

        <span>
          <Text style={{ fontWeight: 200 }}>
            Training range:{" "}
            {dayjs(data.data?.training_date_start).format("MMM D, YYYY")} -{" "}
            {dayjs(data.data?.training_date_end).format("MMM D, YYYY")},
            estimated range:{" "}
            {dayjs(data.data?.estimation_date_start).format("MMM D, YYYY")}-{" "}
            {dayjs(data.data?.estimation_date_end).format("MMM D, YYYY")}
          </Text>
        </span>
      </Space>
    </Page>
  );
};

export default EstimationsDashboard;
