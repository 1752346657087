import axios from "axios";
import { Placement } from "../normalization/types";
import { Account, Provider } from "./types";

export const patchAccount = (
  accountId: string,
  payload: any
): Promise<Account> =>
  axios
    .patch(`/api/v1/accounts/${accountId}`, payload)
    .then((response) => response.data);

export const fetchProviders = (): Promise<Provider[]> =>
  axios.get("/api/v1/providers").then((response) => response.data);

export const fetchAccounts = (
  active?: boolean,
  custom?: boolean,
  csvUploadAllowed?: boolean
): Promise<Account[]> =>
  axios
    .get("/api/v1/accounts", {
      params: {
        active: active,
        custom: custom,
        csv_upload_allowed: csvUploadAllowed,
      },
    })
    .then((response) => response.data);

export const fetchAccount = (accountId: string): Promise<Account> =>
  axios.get(`/api/v1/accounts/${accountId}`).then((response) => response.data);

export const fetchAccountsPlacements = (
  account_id: string
): Promise<Placement[]> =>
  axios
    .get(`/api/v1/accounts/${account_id}/placements`)
    .then((response) => response.data);
