import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Divider,
  message,
  Row,
  Spin,
  Typography,
} from "antd";
import { AxiosError } from "axios";
import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { MainContext } from "../Main";
import { Page } from "../utils/Page";
import { PageTitle } from "../utils/PageTitle";
import { fetchApp } from "./api";
import { EditAppModal } from "./EditAppModal";
import PlatformList from "./PlatformList";

const { Text } = Typography;

const AppDetails: React.FC = () => {
  const { user } = useContext(MainContext);
  const [isFormVisible, setIsFormVisible] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const param_app_id = params.id;

  const app = useQuery(
    ["apps", param_app_id],
    () => fetchApp(param_app_id || "0"),
    {
      onError: (error: AxiosError) => {
        console.log(error);
        error.response &&
          message.error(
            error.response.status === 403
              ? "You have no access to this App."
              : "Encoured an error."
          );
        navigate("/apps");
      },
    }
  );

  return (
    <>
      {!app.data ? (
        <Row justify={"center"}>
          <Spin size="large" tip="Loading..." style={{ marginTop: "100px" }} />
        </Row>
      ) : (
        <>
          <Page>
            <PageTitle
              title={`App ${app.data.name}`}
              breadcrumbs={[
                { label: "Apps", route: "/apps" },
                { label: app.data.name },
              ]}
              extra={
                user?.is_admin && (
                  <Button
                    icon={<EditOutlined />}
                    type="primary"
                    onClick={() => setIsFormVisible(true)}
                  >
                    Edit app
                  </Button>
                )
              }
            />
            <Descriptions column={{ lg: 3, md: 2, sm: 1, xs: 1 }} size="small">
              <Descriptions.Item label="Category">
                {app.isLoading ? <Spin size="small" /> : app.data.category}
              </Descriptions.Item>
              <Descriptions.Item label="Facebook Payout">
                {app.isLoading ? (
                  <Spin size="small" />
                ) : (
                  app.data.facebook_payout || (
                    <Text type="secondary">Unknown</Text>
                  )
                )}
              </Descriptions.Item>
              <Descriptions.Item label="RevShare">
                {app.isLoading ? (
                  <Spin size="small" />
                ) : (
                  app.data.revshare || <Text type="secondary">Unknown</Text>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Atom">
                {app.isLoading ? (
                  <Spin size="small" />
                ) : (
                  app.data.atom || <Text type="secondary">Unknown</Text>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Team">
                {app.isLoading ? (
                  <Spin size="small" />
                ) : (
                  app.data.team || <Text type="secondary">Unknown</Text>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Publisher">
                {app.isLoading ? (
                  <Spin size="small" />
                ) : (
                  app.data.publisher || <Text type="secondary">Unknown</Text>
                )}
              </Descriptions.Item>
            </Descriptions>
            <Divider />
            <PlatformList app={app.data} onPlatformAdd={() => app.refetch()} />
          </Page>
          <EditAppModal
            data={app.data}
            isOpen={isFormVisible}
            setIsOpen={setIsFormVisible}
            apiEndpoint="/api/v1/apps"
            onSuccess={() => {
              app.refetch();
            }}
            entityLabel="app"
          />
        </>
      )}
    </>
  );
};

export default AppDetails;
