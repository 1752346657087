import { Area } from "@ant-design/plots";
import {
  Avatar,
  Col,
  Divider,
  Drawer,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import _, { sumBy, toInteger } from "lodash";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { DataRow } from "../dashboard/types";
var minMax = require("dayjs/plugin/minMax");
dayjs.extend(minMax);

interface ProviderDetailsDrawerProps {
  isOpen: boolean;
  isMissingData: boolean;
  providerId?: string;
  data: DataRow[];
  onClose: () => void;
}

export const ProviderDetailsDrawer: React.FC<ProviderDetailsDrawerProps> = (
  props
) => {
  const chartData = useMemo(() => {
    const data: {
      date: string;
      account_name: string;
      value: number | undefined;
    }[] = _(props.data)
      .map((obj) => ({
        ...obj,
        dateAccount: `${obj.date}_${obj.account_name}`,
      }))
      .groupBy("dateAccount")
      .map((obj, dateAccount) => ({
        date: dateAccount.split("_")[0],
        account_name: dateAccount.split("_")[1],
        value: sumBy(obj, "total_revenue_net"),
      }))
      .value();
    for (const acc of new Set(data.map((obj) => obj.account_name))) {
      for (const day in [...Array(7).keys()]) {
        const missingDate = dayjs("2022-06-08")
          .subtract(toInteger(day), "day")
          .format("YYYY-MM-DD");
        if (
          !data
            .filter((obj) => obj.account_name === acc)
            .map((obj) => obj.date)
            .includes(missingDate)
        ) {
          data.push({
            date: missingDate,
            account_name: acc,
            value: undefined,
          });
        }
      }
    }
    return data.sort((a, b) => a.date.localeCompare(b.date));
  }, [props.data]);

  const isAccountMissingData = (data: DataRow[]) =>
    data.map((obj) => obj.date).length < 7;

  const tableData = useMemo(() => {
    const data = _(props.data)
      .groupBy("account_name")
      .map((obj, account_name) => ({
        account_name: account_name,
        account_id: obj[0].account_id,
        last_date: props.data
          .filter((obj) => obj.account_name === account_name)
          .map((obj) => new Date(obj.date))
          .reduce((a, b) => (a > b ? a : b))
          .toISOString()
          .split("T")[0],
        value: sumBy(obj, "total_revenue_net"),
        status: isAccountMissingData(
          props.data.filter((obj) => obj.account_name === account_name)
        )
          ? "Missing data"
          : "Ok",
      }))
      .sort((a, b) => b.value - a.value)
      .sort((a, b) => a.status.localeCompare(b.status))
      .value();
    return data;
  }, [props.data]);

  return (
    <Drawer
      title="Details"
      placement="right"
      onClose={props.onClose}
      open={props.isOpen}
      size="large"
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row gutter={8} align="middle">
          <Col>
            <Avatar
              src={`/providers/${props.providerId}.png`}
              style={{ backgroundColor: "#EEE" }}
              shape="square"
            />
          </Col>
          <Col flex="auto">
            <span style={{ fontSize: "16px", fontWeight: 600 }}>
              {props.data[0]?.provider_name || ""}
            </span>
          </Col>
          <Col>
            <Tooltip
              title={
                props.isMissingData ? "One or more accunts is missing data" : ""
              }
            >
              <Tag color={props.isMissingData ? "warning" : "green"}>
                {props.isMissingData ? "Missing data" : "All good"}
              </Tag>
            </Tooltip>
          </Col>
        </Row>
        <Area
          data={chartData}
          xField="date"
          yField="value"
          seriesField="account_name"
          style={{ height: 200 }}
          legend={false}
          // xAxis={false}
          smooth={true}
          tooltip={{
            customItems: (originalItems) => {
              originalItems = originalItems.sort(
                (a, b) => (b.data.value || 0) - (a.data.value || 0)
              );
              return originalItems;
            },
            formatter: (datum) => {
              return {
                name: datum.account_name,
                value: datum.value
                  ? "$" +
                    datum.value.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "",
              };
            },
          }}
          yAxis={{
            position: "right",
            label: {
              formatter: (v) => `$${v}`,
            },
          }}
        />
        <Divider />
        <Table
          size="small"
          dataSource={tableData || []}
          pagination={false}
          rowKey={"account_name"}
          columns={[
            {
              title: "Account",
              dataIndex: "account_name",
              render: (name, obj) => (
                <Link to={`/accounts/${obj.account_id}`}>{name}</Link>
              ),
            },
            {
              title: "Last date",
              dataIndex: "last_date",
            },
            {
              title: "Revenue",
              dataIndex: "value",
              align: "right",
              sorter: (a, b) => a.value - b.value,
              render: (value: number, obj) =>
                "$" +
                value.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
            },
            {
              title: "Status",
              dataIndex: "status",
              sorter: (a, b) => a.status.localeCompare(b.status),
            },
          ]}
        />
      </Space>
    </Drawer>
  );
};
