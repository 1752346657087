import axios from "axios";
import { AddUser, EditUser, EntitySuggestion, Scope, User } from "./types";

export const addUser = (user: AddUser): Promise<User> =>
  axios.post("/api/v1/admin/users", user).then((response) => response.data);

export const fetchUsers = (): Promise<User[]> =>
  axios.get("/api/v1/admin/users").then((response) => response.data);

export const fetchEntitySuggestions = (): Promise<EntitySuggestion> =>
  axios
    .get("/api/v1/admin/users/permissions/entites/autocomplete")
    .then((response) => response.data);

export const editUser = (userId: string, user: EditUser): Promise<User> =>
  axios
    .patch(`/api/v1/admin/users/${userId}`, user)
    .then((response) => response.data);

export const addPermission = (
  selectedUserId: string,
  activeUserEmail: string,
  scope: Scope,
  entity: string
): Promise<User> =>
  axios
    .post(`/api/v1/admin/users/${selectedUserId}/permissions`, {
      scope: scope,
      entity: entity,
      user_id: selectedUserId,
      created_by: activeUserEmail,
    })
    .then((response) => response.data);

export const deletePermission = (
  selectedUserId: string,
  scope: Scope,
  entity: string
): Promise<User> =>
  axios
    .delete(`/api/v1/admin/users/${selectedUserId}/permissions`, {
      data: {
        scope: scope,
        entity: entity,
        user_id: selectedUserId,
      },
    })
    .then((response) => response.data);
