import { Line } from "@ant-design/plots";
import { Col, Divider, Row } from "antd";
import dayjs from "dayjs";
import { useMemo } from "react";
import { SpinWrapper } from "../../utils/SpinWrapper";
import { metricsWithoutDollar } from "../Dashboard";
import { ChartMetrics } from "./ChartMetrics";
import { metricColumns } from "./columns";
import { EstimationsResponse } from "./types";

interface ChartProps {
  data?: EstimationsResponse;
  isLoading: boolean;
  estimationDateStart?: string;
}

export const Chart: React.FC<ChartProps> = (props) => {
  const maxDate = useMemo(() => {
    if (props.data?.back_in_time || props.data === undefined) {
      return undefined;
    } else {
      return dayjs(
        Math.max(...props.data.estimations.map((x) => Date.parse(x.date)))
      ).format("YYYY-MM-DD");
    }
  }, [props.data]);

  const metricCol = useMemo(() => {
    if (props.data && props.data.metric) {
      return metricColumns.find((col) => col.dataIndex === props.data!.metric)!;
    } else {
      return undefined;
    }
  }, [props.data?.metric]);

  const data = useMemo(() => {
    if (props.data === undefined) {
      return [];
    } else {
      const dataFiltered = props.data.estimations.filter(
        (row) =>
          row.date >= props.data?.date_start! &&
          row.date <= props.data?.date_end!
      );
      return props.data.back_in_time
        ? [
            ...dataFiltered
              .filter((row) => row.predicted_value)
              .map((row) => ({
                date: row.date,
                value: row.predicted_value!,
                category: "Prediction",
              })),
            ...dataFiltered
              .filter((row) => row.real_value)
              .map((row) => ({
                date: row.date,
                value: row.real_value,
                category: "Real Data",
              })),
          ]
        : dataFiltered.map((row) => ({
            date: row.date,
            value:
              dayjs(row.date) > dayjs(props.estimationDateStart!)
                ? row.predicted_value
                : row.real_value,
          }));
    }
  }, [props.data]);

  return (
    <SpinWrapper spinVisible={props.isLoading}>
      <Row>
        <Col span={props.data?.back_in_time ? 20 : 24}>
          <Line
            data={data}
            xField="date"
            yField="value"
            seriesField={
              props.data && props.data.back_in_time ? "category" : undefined
            }
            xAxis={{
              type: "time",
            }}
            yAxis={{
              label: {
                formatter: (v) =>
                  `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
              },
            }}
            tooltip={{
              customItems: (originalItems) => {
                originalItems = originalItems.sort(
                  (a, b) => b.data.value - a.data.value
                );
                return originalItems;
              },
              formatter: (datum) => {
                const val = metricsWithoutDollar.includes(props.data!.metric!)
                  ? datum.value.toLocaleString(undefined, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "$" +
                    datum.value.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    });
                return props.data!.back_in_time
                  ? {
                      name: datum.category,
                      value: val,
                    }
                  : {
                      name:
                        datum.date >= props.estimationDateStart!
                          ? `${metricCol?.title} (estimated)`
                          : metricCol?.title,
                      value: val,
                    };
              },
            }}
            annotations={
              props.estimationDateStart && maxDate
                ? [
                    {
                      type: "region",
                      start: [props.estimationDateStart, "min"],
                      end: [maxDate, "max"],
                    },
                    {
                      type: "text",
                      offsetY: -10,
                      position: [props.estimationDateStart, "min"],
                      content: "Estimated",
                      offsetX: 5,
                      style: {
                        fontWeight: 300,
                      },
                    },
                    {
                      type: "regionFilter",
                      start: ["start", "start"],
                      end: [
                        "end",
                        dayjs(props.data!.training_date_start).format(
                          "YYYY-MM-DD"
                        ),
                      ],
                      color: "#ffe58f",
                    },
                  ]
                : undefined
            }
          />
        </Col>
        {props.data?.back_in_time !== null && (
          <>
            <Col span={1}>
              <Divider style={{ height: "100%" }} type="vertical" />
            </Col>
            <Col span={3}>
              {props.data?.back_in_time && (
                <ChartMetrics
                  estimatedSum={props.data.back_in_time.estimated_sum}
                  realSum={props.data.back_in_time.real_sum}
                  accuracy={props.data.back_in_time.accuracy}
                  mae={props.data.back_in_time.mae}
                  metric={props.data.metric}
                />
              )}
            </Col>
          </>
        )}
      </Row>
    </SpinWrapper>
  );
};
