import type { InputRef } from "antd";
import { Checkbox, Divider, Input, List, Space, Typography } from "antd";
import { debounce } from "lodash";
import VirtualList from "rc-virtual-list";
import { useEffect, useMemo, useRef, useState } from "react";
import { AutocompleteValues } from "../../utils/types";

const { Text, Paragraph } = Typography;

interface FilterOptionsProps {
  index: string;
  isPopoverOpen: boolean;
  isLoading: boolean;
  options: AutocompleteValues[];
  selectedValues: string[];
  confirmedValues: string[];
  selectValue: (value: string) => void;
  selectAll: (value: boolean) => void;
}

export const FilterOptions: React.FC<FilterOptionsProps> = (props) => {
  const searchInputRef = useRef<InputRef>(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchValueDebounced, setSearchValueDebounced] = useState("");

  useEffect(() => {
    // TODO: shouldn't be like that
    if (props.isPopoverOpen && props.options.length > 0) {
      setTimeout(() => {
        searchInputRef.current!.focus({ cursor: "start" });
      }, 200);
    }
  }, [props.isPopoverOpen, props.options]);

  useEffect(() => searchInputRef.current!.focus({ cursor: "start" }), []);

  const updateSearchValueDebounced = useMemo(
    () => debounce((filter) => setSearchValueDebounced(filter), 250),
    []
  );

  const data = useMemo(
    () =>
      props.options
        .filter((obj) =>
          (obj.label.toLowerCase() || "").includes(
            searchValueDebounced.toLowerCase()
          )
        )
        .sort((a, b) => {
          return (props.confirmedValues.includes(a.value) &&
            props.confirmedValues.includes(b.value)) ||
            (!props.confirmedValues.includes(a.value) &&
              !props.confirmedValues.includes(b.value))
            ? 0
            : props.confirmedValues.includes(a.value)
            ? -1
            : 1;
        }),
    [props.options, searchValueDebounced, props.confirmedValues]
  );

  return (
    <Space
      direction="vertical"
      style={{ width: "100%" }}
      onClick={() => searchInputRef.current!.focus({ cursor: "start" })}
    >
      <Input
        disabled={props.isLoading}
        ref={searchInputRef}
        onChange={(e) => {
          setSearchValue(e.target.value);
          updateSearchValueDebounced(e.target.value);
        }}
        value={searchValue}
        placeholder="Search"
      />
      <div>
        <Checkbox
          disabled={props.isLoading}
          indeterminate={
            !props.isLoading &&
            props.selectedValues.length < props.options.length &&
            props.selectedValues.length > 0
          }
          checked={props.selectedValues.length === props.options.length}
          onChange={(e) => {
            props.selectAll(e.target.checked);
          }}
        >
          Select All
        </Checkbox>
        <Divider style={{ margin: "8px 0 4px 0" }} />
        <List loading={props.isLoading} split={true} className="checkbox-list">
          <VirtualList data={data} height={300} itemHeight={30} itemKey="value">
            {(obj: AutocompleteValues) => (
              <List.Item key={obj.value}>
                <Checkbox
                  value={obj.value}
                  checked={props.selectedValues.includes(obj.value)}
                  onChange={() => props.selectValue(obj.value)}
                >
                  {props.index === "country" ? (
                    <Text style={{ whiteSpace: "nowrap" }}>
                      <img
                        src={`https://bi-public-assets.s3.eu-central-1.amazonaws.com/countries/${obj.value}.png`}
                        onError={(e) =>
                          (e.currentTarget.src = "/country_placeholder.png")
                        }
                        alt={`${obj.value}`}
                      />
                      &nbsp;{obj.label}
                    </Text>
                  ) : (
                    obj.label
                  )}
                </Checkbox>
              </List.Item>
            )}
          </VirtualList>
        </List>
      </div>
    </Space>
  );
};
