import { notification, Spin } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MainContext } from "../Main";

export const GoogleLogin: React.FC = () => {
  const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false);
  const { setUser } = useContext(MainContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (gsiScriptLoaded) return;

    const initializeGsi = () => {
      const { google } = window as any;
      if (!google || gsiScriptLoaded) return;
      setGsiScriptLoaded(true);
      google.accounts.id.initialize({
        client_id:
          "504559993177-3hcm49uounf0hhqfkjmn557l6ju9d0kh.apps.googleusercontent.com",
        callback: handleGoogleSignIn,
      });
      google.accounts.id.renderButton(document.getElementById("buttonDiv"), {
        theme: "outline",
        size: "large",
      });
      google.accounts.id.prompt();
    };

    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.onload = initializeGsi;
    script.async = true;
    script.id = "google-client-script";
    document.querySelector("body")?.appendChild(script);

    // return () => {
    //   // Cleanup function that runs when component unmounts
    //   /* @ts-ignore */
    //   window.google?.accounts.id.cancel();
    //   document.getElementById("google-client-script")?.remove();
    // };
  }, [gsiScriptLoaded]);

  const handleGoogleSignIn = (res: any) => {
    console.log("Google Sign In callback");
    setIsLoading(true);
    if (!res.clientId || !res.credential) return;
    axios
      .get("/api/auth/google/token", {
        params: { token: res.credential },
        withCredentials: true,
      })
      .then((response) => {
        console.log("SUCCESS", response);
        setUser(response.data.user);
        navigate(searchParams.get("redirectTo") || "/apps");
      })
      .catch((error) => {
        if (error.response.data["detail"])
          notification.error({
            message: "Login error",
            description: error.response.data["detail"],
            duration: 10,
          });
        console.log(error.response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Spin spinning={isLoading}>
      <div id="buttonDiv" style={{ margin: "50px" }}></div>
    </Spin>
  );
};
