import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Area } from "@ant-design/plots";
import { Avatar, Card, Col, Row, Space, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import _, { sumBy, toInteger } from "lodash";
import { useMemo } from "react";
import { DataRow } from "../dashboard/types";

const { Text } = Typography;

interface ProviderTileProps {
  onClick: () => void;
  data: DataRow[];
  providerName: string;
  providerId: string;
  isMissingData: boolean;
}

export const ProviderTile: React.FC<ProviderTileProps> = (props) => {
  const data = useMemo(() => {
    const data: { date: string; value: number | undefined }[] = _(props.data)
      .groupBy("date")
      .map((obj, date) => ({
        date: date,
        value: sumBy(obj, "total_revenue_net"),
      }))
      .value();
    for (const day in [...Array(7).keys()]) {
      const missingDate = dayjs("2022-06-07")
        .subtract(toInteger(day), "day")
        .format("YYYY-MM-DD");
      if (!data.map((obj) => obj.date).includes(missingDate)) {
        data.push({
          date: missingDate,
          value: undefined,
        });
      }
    }
    return data.sort((a, b) => a.date.localeCompare(b.date));
  }, [props.data]);

  return (
    <Card style={{ width: 200, height: 175 }} hoverable onClick={props.onClick}>
      <Space direction="vertical" style={{ width: "100%" }} size={8}>
        <Row align="middle">
          <Col flex="auto">
            <Avatar
              src={`/providers/${props.providerId}.png`}
              style={{ backgroundColor: "#EEE" }}
              shape="square"
            />
          </Col>
          <Col>
            <Tooltip
              title={
                props.isMissingData
                  ? "One or more accunts is missing data"
                  : "All good"
              }
              style={{ flex: "end" }}
            >
              {props.isMissingData ? (
                <WarningOutlined
                  style={{ fontSize: "20px", color: "orange" }}
                />
              ) : (
                <CheckCircleOutlined
                  style={{ fontSize: "20px", color: "green" }}
                />
              )}
            </Tooltip>
          </Col>
        </Row>

        <span style={{ fontSize: "16px", fontWeight: 600 }}>
          {props.providerName}
        </span>
        {props.data.length === 0 ? (
          <Text type="secondary">No data</Text>
        ) : (
          <Area
            data={data}
            xField="date"
            yField="value"
            style={{ height: 70, margin: "-10px" }}
            legend={false}
            xAxis={false}
            smooth={true}
            tooltip={{
              formatter: (datum) => {
                return {
                  name: "Rev",
                  value: datum.value
                    ? "$" +
                      datum.value.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "",
                };
              },
            }}
            areaStyle={() =>
              props.isMissingData
                ? {
                    fill: "l(270) 0:#ffffff 1:#fa8c16",
                  }
                : {
                    fill: "l(270) 0:#ffffff 1:#52c41a",
                  }
            }
            color={props.isMissingData ? "orange" : "green"}
            yAxis={false}
          />
        )}
      </Space>
    </Card>
  );
};
