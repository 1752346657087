import { DownloadOutlined, SettingOutlined } from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Row,
  Space,
  Table,
  TableColumnsType,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import { metricsWithoutDollar } from "../Dashboard";
import { dimensionColumns, metricColumns } from "../dashboardColumns";
import { DataRow, QueryResponse } from "../types";
import { DashboardTablePagination } from "./DashboardTablePagination";

interface DashboardTableProps {
  isLoading: boolean;
  data: QueryResponse | undefined;
  selectedMetrics: string[];
  onMetricChange: (metrics: string) => void;
}

export const DashboardTable: React.FC<DashboardTableProps> = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const columns = useMemo(() => {
    return [
      dimensionColumns.find((val) => val.dataIndex === "date")!,
      ...dimensionColumns.filter((val) =>
        ((props.data && props.data.dimensions) || [])
          .filter((val) => val !== "date")
          .includes(val.dataIndex)
      ),
      ...metricColumns.filter((val) =>
        props.selectedMetrics.includes(val.dataIndex)
      ),
    ];
  }, [props.selectedMetrics, props.data?.dimensions]);

  const summaryRow = useMemo(() => {
    return (
      <Table.Summary.Row>
        <Table.Summary.Cell key="0" index={0} align="left">
          Total
        </Table.Summary.Cell>
        {[
          ((props.data && props.data.dimensions) || [])
            .filter((val) => val !== "date")
            .map((col, idx) => {
              const index = 1 + idx;
              return <Table.Summary.Cell key={index} index={index} />;
            }),
          metricColumns
            .filter((val) => props.selectedMetrics.includes(val.dataIndex))
            .map((col, idx) => {
              const index =
                1 + ((props.data && props.data.dimensions) || []).length + idx;
              const sum = ((props.data && props.data.data) || [])
                .map((row) => +(row[col.dataIndex] || 0))
                .reduce((a, b) => a + b, 0);
              const digits = metricsWithoutDollar.includes(col.dataIndex)
                ? 0
                : 2;
              return (
                <Table.Summary.Cell key={index} index={index} align="right">
                  {!sum
                    ? ""
                    : sum.toLocaleString(undefined, {
                        minimumFractionDigits: digits,
                        maximumFractionDigits: digits,
                      })}
                </Table.Summary.Cell>
              );
            }),
        ]}
      </Table.Summary.Row>
    );
  }, [props.data, props.selectedMetrics, props.data?.dimensions]);

  const blockDownloadButtons = useMemo(
    () => ((props.data && props.data.data) || []).length === 15000,
    [props.data]
  );

  const downloadPdf = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    const title = `dashboard ${dayjs().format("YYYY-MM-DD HH:mm")}`;
    const columnsFlat = columns.map((col) => col.dataIndex);
    const metricsFlat = metricColumns.map((col) => col.dataIndex);
    const headers = [
      columns.map((col) =>
        metricsFlat.includes(col.dataIndex) &&
        !metricsWithoutDollar.includes(col.dataIndex)
          ? col.title + "  (in USD)"
          : col.title
      ),
    ];

    const newData = ((props.data && props.data.data) || []).map((row) => {
      const newRow: (string | number)[] = [];
      for (let col of columnsFlat) {
        if (metricsFlat.includes(col)) {
          const digits = metricsWithoutDollar.includes(col) ? 0 : 2;
          newRow.push(
            (row[col] || 0).toLocaleString(undefined, {
              minimumFractionDigits: digits,
              maximumFractionDigits: digits,
            })
          );
        } else {
          newRow.push(row[col] || "");
        }
      }
      return newRow;
    });
    const summaryRow = columns.map((col, idx) => {
      if (idx === 0) {
        return "Total";
      } else {
        const digits = metricsWithoutDollar.includes(col.dataIndex) ? 0 : 2;
        if (metricsFlat.includes(col.dataIndex)) {
          const sum = ((props.data && props.data.data) || [])
            .map((row) => +(row[col.dataIndex] || 0))
            .reduce((a, b) => a + b, 0);
          if (col.dataIndex === "dau") {
            const days = new Set(
              ((props.data && props.data.data) || []).map((row) => row["date"])
            ).size;
            return (sum / days).toLocaleString(undefined, {
              minimumFractionDigits: digits,
              maximumFractionDigits: digits,
            });
          } else {
            return sum.toLocaleString(undefined, {
              minimumFractionDigits: digits,
              maximumFractionDigits: digits,
            });
          }
        } else {
          return "";
        }
      }
    });
    newData.push(summaryRow);

    doc.text(title, 10, 10);

    autoTable(doc, {
      head: headers,
      body: newData,
      tableWidth: "auto",
    });
    doc.save(`dashboard_${dayjs().format("YYYY-MM-DD HH:mm")}.pdf`);
  };

  const getCsvData = () => {
    return ((props.data && props.data.data) || []).map((row) => {
      const newRow: { [x: string]: string | number | undefined } = {};
      for (const col of columns) {
        const title =
          metricColumns.map((col) => col.dataIndex).includes(col.dataIndex) &&
          !metricsWithoutDollar.includes(col.dataIndex)
            ? col.title + "  (in USD)"
            : col.title;
        newRow[title] = row[col.dataIndex];
      }
      return newRow;
    });
  };

  const downloadXlsx = (fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(getCsvData());
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "dashboard");
    XLSX.writeFileXLSX(wb, `${fileName}.xlsx`);
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Row justify={"end"}>
        <Space>
          <Space.Compact>
            <Dropdown
              placement="bottom"
              disabled={props.isLoading}
              menu={{
                selectable: true,
                multiple: true,
                selectedKeys: props.selectedMetrics,
                items: metricColumns.map((col) => ({
                  key: col.dataIndex,
                  label: (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        props.onMetricChange(col.dataIndex);
                      }}
                    >
                      {col.title}
                    </div>
                  ),
                })),
              }}
              trigger={["click"]}
            >
              <Button loading={props.isLoading} icon={<SettingOutlined />}>
                Columns
              </Button>
            </Dropdown>
            <Dropdown
              disabled={props.isLoading}
              menu={{
                items: [
                  {
                    key: "1",
                    label: (
                      <CSVLink
                        filename={`dashboard_${dayjs().format(
                          "YYYY-MM-DD_HH:mm"
                        )}.`}
                        data={getCsvData()}
                        className="btn btn-primary"
                      >
                        CSV
                      </CSVLink>
                    ),
                  },
                  {
                    key: "2",
                    label: "PDF",
                    onClick: downloadPdf,
                  },
                  {
                    key: "3",
                    onClick: () => {
                      downloadXlsx(
                        `dashboard_${dayjs().format("YYYY-MM-DD_HH:mm")}`
                      );
                    },
                    label: "XLSX",
                  },
                ],
              }}
            >
              <Tooltip
                title={
                  blockDownloadButtons
                    ? "Can't download reports while exceeding the row limit."
                    : ""
                }
              >
                <Button
                  disabled={props.isLoading}
                  icon={<DownloadOutlined />}
                />
              </Tooltip>
            </Dropdown>
          </Space.Compact>

          <DashboardTablePagination
            current={currentPage}
            pageSize={pageSize}
            setPage={setCurrentPage}
            setPageSize={setPageSize}
            total={props.data && props.data.data.length}
          />
        </Space>
      </Row>
      <Table
        bordered
        loading={props.isLoading}
        scroll={{ x: true }}
        dataSource={
          props.data &&
          props.data.data.filter(
            (obj, idx) =>
              idx >= currentPage * pageSize - pageSize &&
              idx < currentPage * pageSize
          )
        }
        rowKey={(record) =>
          record["date"] +
          (props.data &&
            props.data.dimensions
              .map((dim) => record[dim]?.toString())
              .join("_"))
        }
        size="middle"
        columns={columns as TableColumnsType<DataRow>}
        pagination={false}
        summary={
          props.data &&
          props.data.dimensions.length > 0 &&
          props.data.data.length > 0
            ? () => summaryRow
            : undefined
        }
      />
      <Row justify={"end"}>
        <DashboardTablePagination
          current={currentPage}
          pageSize={pageSize}
          setPage={setCurrentPage}
          setPageSize={setPageSize}
          total={props.data && props.data.data.length}
        />
      </Row>
    </Space>
  );
};
