import { Layout, Space } from "antd";
import React from "react";

export const Page: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <Layout.Content
      style={{
        overflow: "initial",
        padding: "6px 24px 24px 24px",
      }}
    >
      <Space style={{ width: "100%" }} direction="vertical">
        {children}
      </Space>
    </Layout.Content>
  );
};
