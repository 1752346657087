import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Form,
  message,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tabs,
  Typography,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { App } from "../apps/types";
import { handleApiError } from "../utils/ApiErrorHandler";
import {
  addPermission,
  deletePermission,
  editUser,
  fetchEntitySuggestions,
} from "./api";
import { EditUser, Scope, User } from "./types";

interface UserPermissionsModalProps {
  selectedUser: User;
  activeUser: User;
  isOpen: boolean;
  onCancel: () => void;
  onOk: () => void;
  onUserPermissionChange: (user: User) => void;
}

const UserPermissionsModal: React.FC<UserPermissionsModalProps> = (props) => {
  const [activeTab, setActiveTab] = useState<Scope>("app");
  const [selectedEntity, setSelectedEntity] = useState<string | undefined>();
  const queryClient = useQueryClient();
  const editUserMutation = useMutation({
    mutationFn: (user: EditUser) =>
      editUser(props.selectedUser.id.toString(), user),
    onError: (error) => {
      error && handleApiError(error);
    },
    onSuccess: (response) => {
      props.onUserPermissionChange(response);
      message.success("Success");
    },
  });
  const deletePermissionMutation = useMutation({
    mutationFn: (permission: { entity: string; scope: Scope }) =>
      deletePermission(
        props.selectedUser.id.toString(),
        permission.scope,
        permission.entity
      ),
    onError: (error) => {
      error && handleApiError(error);
    },
    onSuccess: (response) => {
      props.onUserPermissionChange(response);
      message.success("Success");
      activeTab !== "app" && queryClient.invalidateQueries("entitySuggestions");
    },
  });
  const addPermissionMutation = useMutation({
    mutationFn: (permission: { entity: string; scope: Scope }) =>
      addPermission(
        props.selectedUser.id.toString(),
        props.activeUser.email,
        permission.scope,
        permission.entity
      ),
    onError: (error) => {
      error && handleApiError(error);
    },
    onSuccess: (response) => {
      props.onUserPermissionChange(response);
      message.success("Success");
      activeTab !== "app" && queryClient.invalidateQueries("entitySuggestions");
    },
  });
  const entitySuggestions = useQuery(
    "entitySuggestions",
    fetchEntitySuggestions,
    {
      onError: (error) => console.log(error),
    }
  );

  useEffect(() => {
    setSelectedEntity(undefined);
  }, [activeTab]);

  const hasAllAccess = useMemo(
    () =>
      props.selectedUser.is_admin ||
      props.selectedUser.permissions
        .filter((permission) => permission.scope === "company")
        .map((permission) => permission.entity)
        .includes("ALL"),
    [props.selectedUser]
  );

  const options = useMemo(() => {
    if (entitySuggestions.data === undefined) return [];
    if (activeTab === "app") {
      return entitySuggestions.data[activeTab]
        .filter(
          (suggestion: App) =>
            !props.selectedUser.permissions
              .map((obj) => obj.entity)
              .includes(suggestion.id.toString())
        )
        .map((suggestion) => ({
          value: suggestion.id.toString(),
          label: suggestion.name,
        }));
    } else {
      return entitySuggestions.data[activeTab]
        .filter(
          (suggestion) =>
            !props.selectedUser.permissions
              .map((obj) => obj.entity)
              .includes(suggestion)
        )
        .map((suggestion: string) => ({
          value: suggestion,
          label: suggestion,
        }));
    }
  }, [activeTab, entitySuggestions.data, props.selectedUser.permissions]);

  return (
    <Modal
      open={props.isOpen}
      title={`${props.selectedUser.name}'s Permissions`}
      onCancel={() => {
        props.onCancel();
        setActiveTab("app");
      }}
      destroyOnClose={true}
      getContainer={false}
      width={800}
      footer={null}
      closable={
        !addPermissionMutation.isLoading && !deletePermissionMutation.isLoading
      }
    >
      <Form.Item labelCol={{ span: 5 }} label="Admin">
        <Switch
          loading={editUserMutation.isLoading}
          checked={props.selectedUser.is_admin}
          onChange={(e) => {
            editUserMutation.mutate({ is_admin: e });
          }}
        />
      </Form.Item>
      {!props.selectedUser.is_admin && (
        <Form.Item labelCol={{ span: 5 }} label="Access to all apps">
          <Switch
            disabled={props.selectedUser.is_admin}
            loading={editUserMutation.isLoading}
            checked={hasAllAccess}
            onChange={(e) => {
              e
                ? addPermissionMutation.mutate({
                    entity: "ALL",
                    scope: "company",
                  })
                : deletePermissionMutation.mutate({
                    entity: "ALL",
                    scope: "company",
                  });
            }}
          />
        </Form.Item>
      )}
      <Space direction="vertical" style={{ width: "100%" }}>
        {hasAllAccess ? (
          <Alert
            type="info"
            message={
              <>
                Remove the{" "}
                <Typography.Text strong>
                  {props.selectedUser.is_admin ? "Admin" : "All access"}
                </Typography.Text>{" "}
                option to manage accesses granularly.
              </>
            }
          />
        ) : (
          <>
            <Tabs
              onChange={(activeKey) => setActiveTab(activeKey as Scope)}
              items={[
                {
                  key: "app",
                  label: `Apps (${
                    props.selectedUser.permissions.filter(
                      (permission) =>
                        permission.scope === "app" && permission.app
                    ).length
                  })`,
                },
                {
                  key: "team",
                  label: `Teams (${
                    props.selectedUser.permissions.filter(
                      (permission) => permission.scope === "team"
                    ).length
                  })`,
                },
                {
                  key: "company",
                  label: `Companies (${
                    props.selectedUser.permissions.filter(
                      (permission) => permission.scope === "company"
                    ).length
                  })`,
                },
              ]}
            />
            <Table
              bordered
              loading={
                addPermissionMutation.isLoading ||
                deletePermissionMutation.isLoading
              }
              dataSource={props.selectedUser.permissions.filter(
                (permission) => permission.scope === activeTab
              )}
              rowKey={(row) => `${row.scope}_${row.entity_name}`}
              columns={[
                {
                  key: "entity",
                  title:
                    activeTab &&
                    activeTab[0].toUpperCase() + activeTab.slice(1),
                  render: (value) => value.entity_name,
                },
                {
                  key: "assigned_by",
                  title: "Assigned by",
                  dataIndex: "created_by",
                },
                {
                  key: "assigned_at",
                  title: "Assigned at",
                  dataIndex: "created_at",
                },
                {
                  key: "actions",
                  title: "Actions",
                  render: (value) => (
                    <Typography.Link
                      onClick={() => {
                        Modal.confirm({
                          title: "Confirm",
                          icon: <ExclamationCircleOutlined />,
                          content: `Are you sure you want to take away access to ${value.entity_name} from ${props.selectedUser.email}?`,
                          okText: "Remove",
                          cancelText: "Cancel",
                          onOk: () => {
                            deletePermissionMutation.mutate({
                              scope: activeTab,
                              entity:
                                activeTab === "app"
                                  ? value.app!.id
                                  : value.entity,
                            });
                          },
                        });
                      }}
                    >
                      Remove
                    </Typography.Link>
                  ),
                },
              ]}
            />
            <Row justify="space-between">
              <Select
                style={{ width: "80%" }}
                onChange={(value) => setSelectedEntity(value)}
                value={selectedEntity}
                options={options}
                optionFilterProp="label"
                showSearch
                disabled={addPermissionMutation.isLoading}
              />

              <Button
                icon={<PlusOutlined />}
                disabled={
                  selectedEntity === undefined ||
                  addPermissionMutation.isLoading
                }
                onClick={() => {
                  selectedEntity &&
                    addPermissionMutation.mutate({
                      scope: activeTab,
                      entity: selectedEntity,
                    });
                  setSelectedEntity(undefined);
                }}
              >
                Add
              </Button>
            </Row>
          </>
        )}
      </Space>
    </Modal>
  );
};

export default UserPermissionsModal;
