import { Avatar, Typography } from "antd";
import { DataRow } from "./types";

const { Text } = Typography;

export {};

export const metricColumns = [
  {
    title: "Total Revenue Net",
    dataIndex: "total_revenue_net",
    align: "right",
    render: (value: number) =>
      value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    sorter: {
      compare: (a: DataRow, b: DataRow) =>
        a.total_revenue_net - b.total_revenue_net,
      multiple: 10,
    },
  },
  {
    title: "Total Revenue Gross",
    dataIndex: "total_revenue_gross",
    align: "right",
    render: (value: number) =>
      value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    sorter: {
      compare: (a: DataRow, b: DataRow) =>
        a.total_revenue_gross - b.total_revenue_gross,
      multiple: 9,
    },
  },
  {
    title: "Ad Revenue",
    dataIndex: "ad_revenue",
    align: "right",
    render: (value: number) =>
      value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    sorter: {
      compare: (a: DataRow, b: DataRow) => a.ad_revenue - b.ad_revenue,
      multiple: 8,
    },
  },
  {
    title: "IAP Revenue Gross",
    dataIndex: "iap_revenue_gross",
    align: "right",
    render: (value: number) =>
      value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    sorter: {
      compare: (a: DataRow, b: DataRow) =>
        a.iap_revenue_gross - b.iap_revenue_gross,
      multiple: 7,
    },
  },
  {
    title: "IAP Revenue Net",
    dataIndex: "iap_revenue_net",
    align: "right",
    render: (value: number) =>
      value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    sorter: {
      compare: (a: DataRow, b: DataRow) =>
        a.iap_revenue_net - b.iap_revenue_net,
      multiple: 6,
    },
  },
  {
    title: "Sub Revenue gross",
    dataIndex: "sub_revenue_gross",
    align: "right",
    render: (value: number) =>
      value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    sorter: {
      compare: (a: DataRow, b: DataRow) =>
        a.sub_revenue_gross - b.sub_revenue_gross,
      multiple: 5,
    },
  },
  {
    title: "Sub Revenue Net",
    dataIndex: "sub_revenue_net",
    align: "right",
    render: (value: number) =>
      value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    sorter: {
      compare: (a: DataRow, b: DataRow) =>
        a.sub_revenue_net - b.sub_revenue_net,
      multiple: 4,
    },
  },
  {
    title: "Installs",
    dataIndex: "installs",
    align: "right",
    render: (value: number) =>
      value.toLocaleString("en-US", {
        maximumFractionDigits: 0,
      }),
    sorter: {
      compare: (a: DataRow, b: DataRow) => a.installs - b.installs,
      multiple: 3,
    },
  },
  {
    title: "Updates",
    dataIndex: "updates",
    align: "right",
    render: (value: number) =>
      value.toLocaleString("en-US", {
        maximumFractionDigits: 0,
      }),
    sorter: {
      compare: (a: DataRow, b: DataRow) => a.updates - b.updates,
      multiple: 2,
    },
  },
  {
    title: "DAU",
    dataIndex: "dau",
    align: "right",
    render: (value: number) =>
      (value || 0).toLocaleString("en-US", {
        maximumFractionDigits: 0,
      }),
    sorter: {
      compare: (a: DataRow, b: DataRow) => a.dau - b.dau,
      multiple: 1,
    },
  },
];

export const dimensionColumns = [
  {
    title: "Date",
    dataIndex: "date",
    render: (value: string) => (
      <span style={{ whiteSpace: "nowrap" }}>{value}</span>
    ),
    sorter: {
      compare: (a: DataRow, b: DataRow) => a.date.localeCompare(b.date),
      multiple: 21,
    },
  },
  {
    title: "Application",
    dataIndex: "app_name",
    render: (value: string) => value || "Unknown",
    sorter: {
      compare: (a: DataRow, b: DataRow) =>
        (a.app_name || "Unknown").localeCompare(b.app_name || "Unknown"),
      multiple: 20,
    },
  },
  {
    title: "Bundle ID",
    dataIndex: "bundle_id",
    render: (value: string) => value || "Unknown",
    sorter: {
      compare: (a: DataRow, b: DataRow) =>
        (a.bundle_id || "Unknown").localeCompare(b.bundle_id || "Unknown"),
      multiple: 19,
    },
  },
  {
    title: "Platform",
    dataIndex: "platform",
    render: (value: string) => value || "Unknown",
    sorter: {
      compare: (a: DataRow, b: DataRow) =>
        a.platform!.localeCompare(b.platform!),
      multiple: 18,
    },
  },
  {
    title: "Provider",
    dataIndex: "provider_name",
    // render: (value: string) => value || "Unknown",
    render: (value: string, obj: DataRow) =>
      value ? (
        <Text style={{ whiteSpace: "nowrap" }}>
          <Avatar
            src={`/providers/${obj.provider_id}.png`}
            style={{ backgroundColor: "#EEE" }}
            shape="square"
            size={24}
          />
          &nbsp;{value}
        </Text>
      ) : (
        "Unknown"
      ),
    sorter: {
      compare: (a: DataRow, b: DataRow) =>
        (a.provider_name || "Unknown").localeCompare(
          b.provider_name || "Unknown"
        ),
      multiple: 17,
    },
  },
  {
    title: "Country",
    dataIndex: "country",
    // render: (value: string) => value || "Unknown",
    render: (value: string) =>
      value ? (
        <Text style={{ whiteSpace: "nowrap" }}>
          <img
            src={`https://bi-public-assets.s3.eu-central-1.amazonaws.com/countries/${value}.png`}
            onError={(e) => (e.currentTarget.src = "/country_placeholder.png")}
            alt={`${value}`}
          />
          &nbsp;{value}
        </Text>
      ) : (
        "Unknown"
      ),
    sorter: {
      compare: (a: DataRow, b: DataRow) =>
        (a.country || "Unknown").localeCompare(b.country || "Unknown"),
      multiple: 16,
    },
  },
  {
    title: "Account Name",
    dataIndex: "account_name",
    render: (value: string) => value || "Unknown",
    sorter: {
      compare: (a: DataRow, b: DataRow) =>
        (a.account_name || "Unknown").localeCompare(
          b.account_name || "Unknown"
        ),
      multiple: 15,
    },
  },
  {
    title: "Bank Account",
    dataIndex: "bank_account",
    render: (value: string) => value || "Unknown",
    sorter: {
      compare: (a: DataRow, b: DataRow) =>
        (a.bank_account || "Unknown").localeCompare(
          b.bank_account || "Unknown"
        ),
      multiple: 14,
    },
  },
  {
    title: "Region",
    dataIndex: "region",
    render: (value: string) => value || "Unknown",
    sorter: {
      compare: (a: DataRow, b: DataRow) =>
        (a.region || "Unknown").localeCompare(b.region || "Unknown"),
      multiple: 13,
    },
  },
  {
    title: "Category",
    dataIndex: "app_category",
    render: (value: string) => value || "Unknown",
    sorter: {
      compare: (a: DataRow, b: DataRow) =>
        (a.app_category || "Unknown").localeCompare(
          b.app_category || "Unknown"
        ),
      multiple: 12,
    },
  },
  {
    title: "Team",
    dataIndex: "team",
    render: (value: string) => value || "Unknown",
    sorter: {
      compare: (a: DataRow, b: DataRow) =>
        (a.team || "Unknown").localeCompare(b.team || "Unknown"),
      multiple: 11,
    },
  },
  {
    title: "Publisher",
    dataIndex: "publisher",
    render: (value: string) => value || "Unknown",
    sorter: {
      compare: (a: DataRow, b: DataRow) =>
        (a.publisher || "Unknown").localeCompare(b.publisher || "Unknown"),
      multiple: 10,
    },
  },
];
