import { DownloadOutlined } from "@ant-design/icons";
import { Alert, Button, Collapse, Radio, Row, Space } from "antd";
import dayjs from "dayjs";
import { debounce, isEmpty, isEqual } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { Page } from "../utils/Page";
import { PageTitle } from "../utils/PageTitle";
import { SpinWrapper } from "../utils/SpinWrapper";
import { filterEmptyQueryFilters } from "../utils/UtilsFunctions";
import { ApiError } from "../utils/types";
import { DashboardRangePicker } from "./DashboardRangePicker";
import { DimensionsPicker } from "./DimensionsPicker";
import { fetchDashboardData } from "./api";
import { DashboardChart } from "./charts/DashboardChart";
import { dimensionColumns, metricColumns } from "./dashboardColumns";
import { DashboardFilters } from "./filters/DashboardFilters";
import { Presets } from "./presets/Presets";
import { DashboardTable } from "./table/DashboardTable";
import {
  QueryFilters,
  QueryParams,
  QueryParamsOptional,
  TimeGranularity,
} from "./types";

const defaultMetricsSelected = [
  "ad_revenue",
  "total_revenue_net",
  "total_revenue_gross",
  "iap_revenue_net",
  "sub_revenue_net",
  "installs",
];

const { Panel } = Collapse;

export const metricsWithoutDollar = ["dau", "updates", "installs"];

const Dashboard: React.FC = () => {
  const [initLoad, setInitLoad] = useState(false);
  const [selectedMetrics, setSelectedMetrics] = useState<string[]>(
    defaultMetricsSelected
  );
  const [filters, setFilters] = useState<QueryFilters>({});
  const [queryParams, setQueryParams] = useState<QueryParams>({
    timeGranularity: "daily",
    dateStart: dayjs(new Date(2022, 5, 8)).subtract(29, "d"),
    dateEnd: dayjs(new Date(2022, 5, 8)).subtract(1, "d"),
    dimensions: ["date"],
  });
  const updateQueryParams = (newValues: QueryParamsOptional) =>
    setQueryParams((prevData) => ({ ...prevData, ...newValues }));
  let [searchParams, setSearchParams] = useSearchParams();
  const [permissionError, setPermissionError] = useState("");
  const dashboardData = useQuery(
    ["dashboard_data"],
    () =>
      fetchDashboardData({
        date_start: queryParams.dateStart.format("YYYY-MM-DD"),
        date_end: queryParams.dateEnd.format("YYYY-MM-DD"),
        filters: filters,
        time_granularity: queryParams.timeGranularity,
        dimensions: [
          "date",
          ...(queryParams.dimensions.filter((val) => val !== "date") || []),
        ],
      }),
    {
      enabled: false,
      onError: (error: ApiError) => {
        console.log(error);
        error.response &&
          error.response.data.detail &&
          setPermissionError(error.response.data.detail);
      },
      onSuccess: (response) => {
        console.log(response);
        updateUrl();
      },
    }
  );
  const dashboardDataCsv = useQuery(
    ["dashboard_data_csv"],
    () =>
      fetchDashboardData({
        date_start: queryParams.dateStart.format("YYYY-MM-DD"),
        date_end: queryParams.dateEnd.format("YYYY-MM-DD"),
        filters: filters,
        time_granularity: queryParams.timeGranularity,
        dimensions: [
          "date",
          ...(queryParams.dimensions.filter((val) => val !== "date") || []),
        ],
        format: "csv",
        metrics: metricColumns
          .map((col) => col.dataIndex)
          .filter((col) => selectedMetrics.includes(col)),
      }),
    {
      enabled: false,
      onError: (error: ApiError) => {
        console.log(error);
        error.response &&
          error.response.data.detail &&
          setPermissionError(error.response.data.detail);
      },
    }
  );

  useEffect(() => {
    handleUrl();
  }, []);

  useEffect(() => {
    if (initLoad) {
      dashboardData.refetch();
    }
  }, [initLoad]);

  useEffect(() => {
    setSearchParams((prev) => {
      return {
        ...prev,
        metrics: selectedMetrics.join(","),
      };
    });
  }, [selectedMetrics]);

  const refetchData = useMemo(
    () => debounce(() => dashboardData.refetch(), 100),
    []
  );

  useEffect(() => {
    if (
      !isEqual(
        {
          ...queryParams,
          dateStart: queryParams.dateStart.format("YYYY-MM-DD"),
          dateEnd: queryParams.dateEnd.format("YYYY-MM-DD"),
          dimensions: [
            "date",
            ...queryParams.dimensions.filter((val) => val !== "date"),
          ].sort(),
          filters: filterEmptyQueryFilters(filters),
        },
        {
          dateStart: dashboardData.data && dashboardData.data.date_start,
          dateEnd: dashboardData.data && dashboardData.data.date_end,
          timeGranularity:
            dashboardData.data && dashboardData.data.time_granularity,
          dimensions:
            dashboardData.data && dashboardData.data.dimensions.sort(),
          filters:
            dashboardData.data &&
            filterEmptyQueryFilters(dashboardData.data.filters),
        }
      )
    ) {
      refetchData();
    }
  }, [queryParams, filters, dashboardData.data]);

  const handleUrl = () => {
    let newQueryParams = {
      ...queryParams,
    };
    const timeGranularity = searchParams.get("time_granularity") || "daily";
    if (
      ["daily", "weekly", "monthly", "yearly", "total"].includes(
        timeGranularity
      )
    )
      newQueryParams.timeGranularity = timeGranularity as TimeGranularity;
    let dimensions: string | string[] | null = searchParams.get("dimensions");
    if (dimensions) {
      dimensions = dimensions
        .split(",")
        .filter((val) =>
          dimensionColumns.map((col) => col.dataIndex).includes(val)
        );
      if (dimensions.length > 0) newQueryParams.dimensions = dimensions;
    }
    const metrics = searchParams.get("metrics");
    if (metrics) {
      setSelectedMetrics(
        metrics
          .split(",")
          .filter((val) =>
            metricColumns.map((col) => col.dataIndex).includes(val)
          )
      );
    }
    if (searchParams.get("filters")) {
      const urlFilters = Object.fromEntries(
        new URLSearchParams(searchParams.get("filters") as string).entries()
      );
      for (let key of Object.keys(urlFilters)) {
        if (!dimensionColumns.map((col) => col.dataIndex).includes(key)) {
          delete urlFilters[key];
        }
      }
      if (Object.keys(urlFilters).length > 0) {
        const newFilters: { [x: string]: { [y: string]: string | string[] } } =
          {};
        for (const [key, value] of Object.entries(urlFilters)) {
          newFilters[key] = Object.fromEntries(
            new URLSearchParams(value).entries()
          );
          newFilters[key].value = (newFilters[key].value as string).split(",");
        }
        setFilters(newFilters as QueryFilters);
      }
    }
    const dateEnd = dayjs(searchParams.get("date_end"));
    const dateStart = dayjs(searchParams.get("date_start"));
    if (dateStart.isValid() && dateEnd.isValid()) {
      newQueryParams.dateEnd = dateEnd;
      newQueryParams.dateStart = dateStart;
    }
    setQueryParams(newQueryParams);
    setTimeout(() => setInitLoad(true), 300);
  };

  const updateUrl = () => {
    let newFilters: { [x: string]: string } = {};
    for (const [key, value] of Object.entries(filters).filter(
      ([_key, _value]) => (_value?.value || []).length > 0
    )) {
      newFilters[key] = new URLSearchParams(value as any).toString();
    }
    setSearchParams({
      date_start: queryParams.dateStart.format("YYYY-MM-DD"),
      date_end: queryParams.dateEnd.format("YYYY-MM-DD"),
      metrics: selectedMetrics.join(","),
      time_granularity: queryParams.timeGranularity,
      ...(!isEmpty(filters) && {
        filters: new URLSearchParams(newFilters).toString(),
      }),
      ...(queryParams.dimensions && {
        dimensions: (queryParams.dimensions || []).join(","),
      }),
    });
  };

  const updateTimeGranularity = (newValue: TimeGranularity) => {
    if (newValue === "total" && queryParams.dimensions.includes("date")) {
      updateQueryParams({
        dimensions: queryParams.dimensions.filter((val) => val !== "date"),
        timeGranularity: newValue as TimeGranularity,
      });
    } else if (
      newValue !== "total" &&
      !queryParams.dimensions.includes("date")
    ) {
      updateQueryParams({
        dimensions: [...queryParams.dimensions, "date"],
        timeGranularity: newValue as TimeGranularity,
      });
    } else {
      updateQueryParams({
        timeGranularity: newValue as TimeGranularity,
      });
    }
  };

  return (
    <Page>
      <PageTitle
        title="Dashboard"
        breadcrumbs={[{ label: "Dashboard", route: undefined }]}
        align={"start"}
        extra={
          <Presets
            setTimeGranularity={(timeGranularity) =>
              setQueryParams((prevData) => ({
                ...prevData,
                timeGranularity: timeGranularity,
              }))
            }
            setDimensions={(dimensions) =>
              setQueryParams((prevData) => ({
                ...prevData,
                dimensions: dimensions,
              }))
            }
            setFilters={(newValues) => {
              setFilters({});
              setFilters((prevData) => ({ ...prevData, ...newValues }));
            }}
            queryValues={{
              dimensions: queryParams.dimensions,
              filters: filters,
              timeGranularity: queryParams.timeGranularity,
            }}
            metrics={selectedMetrics}
            setMetrics={setSelectedMetrics}
          />
        }
      />
      <Row justify={"space-between"}>
        <Space>
          {/* Date range picker */}
          <DashboardRangePicker
            values={[queryParams.dateStart, queryParams.dateEnd]}
            onChange={(newValues) =>
              setQueryParams((prevValues) => ({
                ...prevValues,
                dateStart: dayjs(newValues[0]),
                dateEnd: dayjs(newValues[1]),
              }))
            }
          />
          {/* Time granularity picker */}
          <Radio.Group
            options={[
              { value: "daily", label: "Daily" },
              { value: "weekly", label: "Weekly" },
              { value: "monthly", label: "Monthly" },
              { value: "yearly", label: "Yearly" },
              { value: "total", label: "Total" },
            ]}
            value={queryParams.timeGranularity}
            onChange={(e) =>
              updateTimeGranularity(e.target.value as TimeGranularity)
            }
            optionType="button"
            buttonStyle="solid"
          />
        </Space>
      </Row>
      <Space direction="vertical" style={{ width: "100%" }}>
        {/* Filters picker */}
        <DashboardFilters
          isLoading={dashboardData.isLoading || dashboardData.isFetching}
          filters={filters}
          setFilters={(newValues) =>
            setFilters((prevData) => ({ ...prevData, ...newValues }))
          }
          deleteFilter={(filter) => {
            const newFilters = { ...filters };
            delete newFilters[filter];
            setFilters(newFilters);
          }}
        />
        {/* Dimension picker */}
        <DimensionsPicker
          queryParams={queryParams}
          updateQueryParams={(newValues) =>
            setQueryParams((prevData) => ({ ...prevData, ...newValues }))
          }
        />
        {/* Permission error alert */}
        {permissionError !== "" && (
          <Alert showIcon type="error" message={permissionError} />
        )}{" "}
        {((dashboardData.data && dashboardData.data.data) || []).length ===
          15000 && (
          <Alert
            showIcon
            type="warning"
            action={
              <Button
                size="small"
                type="text"
                onClick={() => dashboardDataCsv.refetch()}
                loading={dashboardDataCsv.isFetching}
                icon={<DownloadOutlined />}
              >
                Download
              </Button>
            }
            message="Reached 15 000 rows limit, reduce dimensions or apply more
                filters and reload data or click the button to download a file
                with full data."
          />
        )}
        {/* Data chart */}
        {dashboardData.data && dashboardData.data.data.length > 0 && (
          <Collapse defaultActiveKey={"chart"}>
            <Panel key="chart" header="Chart">
              <SpinWrapper
                spinVisible={
                  dashboardData.isLoading || dashboardData.isFetching
                }
                hideTip
              >
                <DashboardChart
                  isLoading={
                    dashboardData.isLoading || dashboardData.isFetching
                  }
                  data={dashboardData.data}
                  rowLimitError={
                    ((dashboardData.data && dashboardData.data.data) || [])
                      .length === 15000
                  }
                />
              </SpinWrapper>
            </Panel>
          </Collapse>
        )}
        {/* Data table */}
        <DashboardTable
          isLoading={dashboardData.isLoading || dashboardData.isFetching}
          data={dashboardData.data}
          selectedMetrics={selectedMetrics}
          onMetricChange={(metric) => {
            selectedMetrics.includes(metric)
              ? setSelectedMetrics(
                  selectedMetrics.filter((val) => val !== metric)
                )
              : setSelectedMetrics([...selectedMetrics, metric]);
          }}
        />
      </Space>
    </Page>
  );
};

export default Dashboard;
