import { PlusOutlined, SearchOutlined } from "@ant-design/icons";

import { Button, Input, Space, Table, Tag } from "antd";

import { ColumnsType } from "antd/lib/table";
import React, { useContext, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { MainContext } from "../Main";
import { Page } from "../utils/Page";
import { PageTitle } from "../utils/PageTitle";
import { AccountForm } from "./AccountForm";
import { fetchAccounts, fetchProviders } from "./api";
import { Account } from "./types";

const Accounts: React.FC = () => {
  const [filter, setFilter] = useState("");
  const { user } = useContext(MainContext);
  const navigate = useNavigate();
  const [isFormVisible, setIsFormVisible] = useState(false);
  const queryClient = useQueryClient();
  const { data: providers, isLoading: isLoadingProviders } = useQuery(
    "providers",
    fetchProviders,
    {
      onError: (error) => console.log(error),
    }
  );
  const { data: accounts, isLoading: isLoadingAccounts } = useQuery(
    "accounts",
    () => fetchAccounts(),
    {
      onError: (error) => console.log(error),
    }
  );

  const filteredData = useMemo(
    () =>
      filter === ""
        ? accounts === undefined
          ? []
          : accounts
        : (accounts || []).filter((el) => {
            const filterLower = filter.toLowerCase();
            return (
              el.id.toString().includes(filterLower) ||
              el.bank_account?.toLowerCase().includes(filterLower) ||
              el.provider_id.toLowerCase().includes(filterLower) ||
              el.name.toLowerCase().includes(filterLower)
            );
          }),
    [filter, accounts]
  );
  return (
    <Page>
      <PageTitle
        title="Accounts"
        breadcrumbs={[{ label: "Accounts" }]}
        extra={
          user?.is_admin && (
            <Button
              loading={isLoadingProviders}
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => setIsFormVisible(true)}
              disabled={isLoadingProviders}
            >
              Add account
            </Button>
          )
        }
      />
      <Space direction="vertical" style={{ width: "100%" }}>
        <Input
          placeholder="Search by account name or provider"
          prefix={<SearchOutlined />}
          value={filter}
          autoFocus
          allowClear
          onChange={(el) => setFilter(el.currentTarget.value)}
          size="large"
        />
        <Table
          bordered
          columns={
            [
              {
                title: "ID",
                dataIndex: "id",
              },
              {
                title: "Provider",
                dataIndex: "provider_id",
                filters: Array.from(
                  new Set((accounts || []).map((row) => row.provider_id))
                ).map((val) => ({
                  text: val,
                  value: val,
                })),
                onFilter: (value, record) => record.provider_id === value,
                filterSearch: (input, record) =>
                  (record as { text: string; value: string }).value.includes(
                    input
                  ),
                sorter: (a, b) => a.provider_id.localeCompare(b.provider_id),
              },
              {
                title: "Name",
                render: (val) =>
                  user?.is_admin ? (
                    <Link to={`/accounts/${val.id}`}>{val.name}</Link>
                  ) : (
                    val.name
                  ),
                filters: Array.from(
                  new Set((accounts || []).map((row) => row.name))
                ).map((val) => ({
                  text: val,
                  value: val,
                })),
                onFilter: (value, record) => record.name === value,
                filterSearch: (
                  input,
                  record: { text: string; value: string }
                ) => record.value.includes(input),
                sorter: (a, b) => a.name.localeCompare(b.name),
              },
              {
                title: "Bank Account",
                dataIndex: "bank_account",
                filters: Array.from(
                  new Set((accounts || []).map((row) => row.bank_account))
                ).map((val) => ({
                  text: val,
                  value: val,
                })),
                onFilter: (value, record) => record.bank_account === value,
                filterSearch: (
                  input,
                  record: { text: string; value: string }
                ) => record.value.includes(input),
                sorter: (a, b) => a.name.localeCompare(b.name),
              },
              {
                title: "Enabled",
                dataIndex: "is_active",
                render: (value: string) =>
                  value ? (
                    <Tag color="green">Enabled</Tag>
                  ) : (
                    <Tag color="red">Disabled</Tag>
                  ),
                sorter: (a, b) => (b.is_active ? 1 : a.is_active ? -1 : 0),
              },
            ] as ColumnsType<Account>
          }
          dataSource={filteredData}
          rowKey="id"
          loading={isLoadingAccounts}
          scroll={{ x: true }}
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
            position: ["bottomRight", "topRight"],
          }}
        />
      </Space>
      <AccountForm
        isOpen={isFormVisible}
        setIsOpen={setIsFormVisible}
        apiEndpoint="/api/v1/accounts"
        onSuccess={(data) => {
          navigate(`/accounts/${data.id}`);
          queryClient.invalidateQueries("accounts");
        }}
        entityLabel="account"
        providers={providers || []}
        onSave={() => {}}
      />
    </Page>
  );
};

export default Accounts;
