import axios from "axios";
import dayjs from "dayjs";
import { Job, ReloadDataPayload } from "./types";

export const reloadData = (payload: ReloadDataPayload) =>
  axios.post("/api/v1/jobs/reload-data", payload);

export const fetchJobs = (): Promise<Job[]> =>
  axios
    .get(
      `/api/v1/jobs?date_start=${dayjs(new Date())
        .subtract(2, "d")
        .format("YYYY-MM-DD")}`
    )
    .then((response) => response.data);

export const fetchAuotomaticJobs = (): Promise<Job[]> =>
  axios.get("/api/v1/jobs/automatic").then((response) => response.data);
