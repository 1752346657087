import { SaveOutlined } from "@ant-design/icons";
import { Input, Modal, Typography } from "antd";
import { useEffect, useState } from "react";

interface PresetNameModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onOk: (presetName: string) => void;
  isLoading: boolean;
}

const { Text } = Typography;

export const PresetNameModal2: React.FC<PresetNameModalProps> = (props) => {
  const [presetName, setPresetName] = useState<string | undefined>();
  const [validationError, setValidationError] = useState(false);

  useEffect(() => {
    if (!props.isOpen) {
      setPresetName(undefined);
      setValidationError(false);
    }
  }, [props.isOpen]);

  const validate = () => presetName && presetName.length >= 3;

  useEffect(() => {
    if (validate() && validationError) {
      setValidationError(false);
    }
  }, [presetName]);

  return (
    <Modal
      title="Preset name"
      open={props.isOpen}
      destroyOnClose={true}
      closable={!props.isLoading}
      okText="Save"
      onCancel={() => {
        props.onCancel();
      }}
      onOk={async () => {
        if (validate()) {
          props.onOk(presetName as string);
        } else {
          setValidationError(true);
        }
      }}
      okButtonProps={{
        icon: <SaveOutlined />,
      }}
    >
      <Input
        status={validationError ? "error" : ""}
        maxLength={255}
        value={presetName}
        onChange={(e) => setPresetName(e.target.value)}
        placeholder="Type preset name..."
        disabled={props.isLoading}
      />
      {validationError && (
        <Text type="danger">
          Preset name must contain at least 3 characters
        </Text>
      )}
    </Modal>
  );
};
