import {
  AndroidFilled,
  AppleFilled,
  ExpandOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Row, Space, Typography } from "antd";
import { useContext, useState } from "react";
import { MainContext } from "../Main";
import { PlatformForm } from "./PlatformForm";
import { App, Platform } from "./types";

const { Title } = Typography;

interface PlatformListProps {
  app: App;
  onPlatformAdd: () => void;
}

const PlatformList: React.FC<PlatformListProps> = (props) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState<
    Platform | undefined
  >(undefined);
  const { user } = useContext(MainContext);

  return (
    <Space style={{ width: "100%" }} direction="vertical">
      <Title level={3}>Platforms</Title>
      <Row gutter={[24, 24]}>
        {props.app.platforms.map((platform: Platform) => {
          return (
            <Col key={platform.id} xs={24} sm={24} md={12} xl={8}>
              <Card
                style={{ width: "100%", height: "130px" }}
                hoverable={user?.is_admin}
                onClick={
                  user?.is_admin
                    ? () => {
                        setIsFormVisible(true);
                        setSelectedPlatform(platform);
                      }
                    : undefined
                }
              >
                <Card.Meta
                  title={
                    <Space size={4}>
                      {platform.name.toLowerCase().includes("google play") ? (
                        <AndroidFilled />
                      ) : platform.name.toLowerCase().includes("ios") ? (
                        <AppleFilled />
                      ) : (
                        <ExpandOutlined />
                      )}{" "}
                      {platform.name}
                    </Space>
                  }
                  description={
                    <>
                      {platform.bundle_id
                        ? `Bundle ID: ${platform.bundle_id}`
                        : ""}
                      <br />
                      {platform.store_id
                        ? `Store ID: ${platform.store_id}`
                        : ""}
                      <br />
                      {`Placements: ${platform.placements.length}`}
                    </>
                  }
                  style={{ marginBottom: "16px" }}
                />
              </Card>
            </Col>
          );
        })}
        {user?.is_admin && (
          <Col xs={24} sm={24} md={12} xl={8}>
            <Button
              style={{ width: "100%", height: "130px" }}
              className="newButton"
              type="dashed"
              icon={<PlusOutlined />}
              onClick={() => {
                setSelectedPlatform(undefined);
                setIsFormVisible(true);
              }}
            >
              New platform
            </Button>
          </Col>
        )}
      </Row>
      <PlatformForm
        data={selectedPlatform}
        app={props.app}
        isOpen={isFormVisible}
        setIsOpen={setIsFormVisible}
        apiEndpoint="/api/v1/platforms"
        onSuccess={() => {
          props.onPlatformAdd();
        }}
        entityLabel="platform"
        platform={selectedPlatform}
        widthPx={1000}
      />
    </Space>
  );
};

export default PlatformList;
