import {
  DownloadOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Input, Space, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";

import { debounce } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { MainContext } from "../Main";
import { handleApiError } from "../utils/ApiErrorHandler";
import { Page } from "../utils/Page";
import { PageTitle } from "../utils/PageTitle";
import { AppForm } from "./AppForm";
import { fetchApps, fetchAppsCsv } from "./api";
import { App, Platform } from "./types";

const Apps: React.FC = () => {
  const [filter, setFilter] = useState("");
  const [filterDebounced, setFilterDebounced] = useState("");
  const [isFormVisible, setIsFormVisible] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(MainContext);
  const queryClient = useQueryClient();
  const { data: apps, isLoading } = useQuery("apps", fetchApps, {
    onError: (error) => console.log(error),
  });
  const appsCsv = useQuery("apps_csv", fetchAppsCsv, {
    onError: (error) => error && handleApiError(error),
    enabled: false,
  });

  const updateFilter = useMemo(
    () => debounce((filter) => setFilterDebounced(filter), 250),
    []
  );

  const filteredData = useMemo(
    () =>
      filterDebounced === ""
        ? apps
          ? apps
          : []
        : (apps ? apps : []).filter((el) => {
            const filterLower = filterDebounced.toLowerCase();
            return (
              el.id.toString().includes(filterLower) ||
              el.name.toLowerCase().includes(filterLower) ||
              el.category?.toLowerCase().includes(filterLower) ||
              el.platforms
                .map((x) => x.name?.toLowerCase())
                .join(" ")
                .includes(filterLower) ||
              el.platforms
                .map((platform) => platform.bundle_id?.toLowerCase())
                .join(" ")
                .includes(filterLower) ||
              el.platforms
                .map((platform) => platform.store_id)
                .join(" ")
                .includes(filterLower) ||
              (el.atom && el.atom.toLowerCase().includes(filterLower)) ||
              (el.team && el.team.toLowerCase().includes(filterLower)) ||
              (el.publisher &&
                el.publisher.toLowerCase().includes(filterLower)) ||
              (el.revshare && el.revshare.toString().includes(filterLower))
            );
          }),
    [filterDebounced, apps]
  );

  const filtersCategory = useMemo(() => {
    return Array.from(new Set(apps ? apps.map((row) => row.category) : [])).map(
      (val) => ({
        text: val,
        value: val,
      })
    );
  }, [apps]);

  const filtersAtom = useMemo(() => {
    return Array.from(new Set(apps ? apps.map((row) => row.atom) : [])).map(
      (val) => ({
        text: val,
        value: val,
      })
    );
  }, [apps]);

  const filtersTeam = useMemo(() => {
    return Array.from(new Set(apps ? apps.map((row) => row.team) : [])).map(
      (val) => ({
        text: val,
        value: val,
      })
    );
  }, [apps]);

  const filtersPublisher = useMemo(() => {
    return Array.from(
      new Set(apps ? apps.map((row) => row.publisher) : [])
    ).map((val) => ({
      text: val,
      value: val,
    }));
  }, [apps]);

  return (
    <Page>
      <PageTitle
        title="Apps"
        breadcrumbs={[{ label: "Apps", route: undefined }]}
        extra={
          user?.is_admin && (
            <Space>
              <Tooltip
                key="1"
                title="Downloads CSV file with list of apps and their platforms."
              >
                <Button
                  icon={appsCsv.isFetching ? undefined : <DownloadOutlined />}
                  onClick={() => appsCsv.refetch()}
                  loading={appsCsv.isFetching}
                >
                  CSV
                </Button>
              </Tooltip>
              <Button
                key="2"
                icon={<PlusOutlined />}
                type="primary"
                loading={isLoading}
                onClick={() => setIsFormVisible(true)}
              >
                Add app
              </Button>
            </Space>
          )
        }
      />
      <Space direction="vertical" style={{ width: "100%" }}>
        <Input
          placeholder="Search by anything"
          prefix={<SearchOutlined />}
          value={filter}
          autoFocus
          allowClear
          onChange={(el) => {
            setFilter(el.currentTarget.value);
            updateFilter(el.currentTarget.value);
          }}
          size="large"
        />
        <Table
          columns={
            [
              {
                title: "ID",
                dataIndex: "id",
                sorter: (a, b) => a.id - b.id,
              },
              {
                title: "Name",
                sorter: (a, b) => a.name.localeCompare(b.name),
                render: (val) => <Link to={`/apps/${val.id}`}>{val.name}</Link>,
              },
              {
                title: "Category",
                dataIndex: "category",
                filters: filtersCategory,
                onFilter: (value, record) => record.category === value,
                filterSearch: (
                  input,
                  record: { text: string; value: string }
                ) => record.value.includes(input),
                sorter: (a, b) =>
                  a.category && b.category
                    ? a.category.localeCompare(b.category)
                    : a.category
                    ? 1
                    : b.category
                    ? -1
                    : 0,
              },
              {
                title: "Platforms",
                dataIndex: "platforms",
                render: (platforms: Platform[]) =>
                  Array.from(new Set(platforms.map((x) => x.name))).join(", "),
              },
              {
                title: "RevShare",
                dataIndex: "revshare",
                sorter: (a, b) => {
                  return (a.revshare || 0) - (b.revshare || 0);
                },
              },
              {
                title: "JIRA Atom",
                dataIndex: "atom",
                filters: filtersAtom,
                onFilter: (value, record) => record.atom === value,
                filterSearch: (
                  input,
                  record: { text: string; value: string }
                ) => record.value.includes(input),
                sorter: (a, b) =>
                  a.atom && b.atom
                    ? a.atom.localeCompare(b.atom)
                    : a.atom
                    ? 1
                    : b.atom
                    ? -1
                    : 0,
              },
              {
                title: "Team",
                dataIndex: "team",
                filters: filtersTeam,
                onFilter: (value, record) => record.team === value,
                filterSearch: (
                  input,
                  record: { text: string; value: string }
                ) => record.value.includes(input),
                sorter: (a, b) =>
                  a.team && b.team
                    ? a.team.localeCompare(b.team)
                    : a.team
                    ? 1
                    : b.team
                    ? -1
                    : 0,
              },
              {
                title: "Publisher",
                dataIndex: "publisher",
                filters: filtersPublisher,
                onFilter: (value, record) => record.publisher === value,
                filterSearch: (
                  input,
                  record: { text: string; value: string }
                ) => record.value.includes(input),
                sorter: (a, b) =>
                  a.publisher && b.publisher
                    ? a.publisher.localeCompare(b.publisher)
                    : a.publisher
                    ? 1
                    : b.publisher
                    ? -1
                    : 0,
              },
            ] as ColumnsType<App>
          }
          dataSource={filteredData}
          rowKey="id"
          loading={isLoading}
          scroll={{ x: true }}
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
            position: ["bottomRight", "topRight"],
          }}
        />
      </Space>

      <AppForm
        isOpen={isFormVisible}
        setIsOpen={setIsFormVisible}
        apiEndpoint="/api/v1/apps"
        onSuccess={(data) => {
          navigate(`/apps/${data.id}`);
          queryClient.invalidateQueries("apps");
        }}
        entityLabel="app"
      />
    </Page>
  );
};

export default Apps;
