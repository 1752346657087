import axios from "axios";
import fileDownload from "js-file-download";
import {
  AssignPlacementPayload,
  Placement,
  PlacementAssignment,
} from "./types";

export const postForceRefresh = () =>
  axios.post("/api/v1/jobs/transfer-tables");

export const fetchPlacements = (assigned: boolean): Promise<Placement[]> =>
  axios
    .get("/api/v1/placements", { params: { assigned: assigned } })
    .then((response) => response.data);

export const assignPlacements = (payload: AssignPlacementPayload) =>
  axios.post("/api/v1/placements/assign", payload);

export const unassignPlacements = (payload: PlacementAssignment[]) =>
  axios.post("/api/v1/placements/unassign", payload);

export const fetchExchangeRates = (dateStart: string, dateEnd: string) =>
  axios
    .get(
      `/api/v1/utils/exchange-rates?date_start=${dateStart}&date_end=${dateEnd}`,
      { responseType: "blob" }
    )
    .then((response) => {
      fileDownload(response.data, "dashboard_exchange_rates.csv");
    });
