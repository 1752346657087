import { DataRow } from "../types";

export const metricColumns = [
  {
    title: "Total Rev Net",
    dataIndex: "total_revenue_net",
    align: "right",
    render: (value: number) =>
      value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    sorter: {
      compare: (a: DataRow, b: DataRow) =>
        a.total_revenue_net - b.total_revenue_net,
      multiple: 1,
    },
  },
  {
    title: "Total Rev Gross",
    dataIndex: "total_revenue_gross",
    align: "right",
    render: (value: number) =>
      value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    sorter: {
      compare: (a: DataRow, b: DataRow) =>
        a.total_revenue_gross - b.total_revenue_gross,
      multiple: 2,
    },
  },
  {
    title: "Ad Rev",
    dataIndex: "ad_revenue",
    align: "right",
    render: (value: number) =>
      value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    sorter: {
      compare: (a: DataRow, b: DataRow) => a.ad_revenue - b.ad_revenue,
      multiple: 3,
    },
  },
  {
    title: "IAP + SUB Rev Gross",
    dataIndex: "iap_sub_revenue_gross",
    align: "right",
    render: (value: number) =>
      value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    sorter: {
      compare: (a: DataRow, b: DataRow) =>
        a.iap_revenue_gross - b.iap_revenue_gross,
      multiple: 4,
    },
  },
  {
    title: "IAP + SUB Rev Net",
    dataIndex: "iap_sub_revenue_net",
    align: "right",
    render: (value: number) =>
      value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    sorter: {
      compare: (a: DataRow, b: DataRow) =>
        a.iap_revenue_net - b.iap_revenue_net,
      multiple: 5,
    },
  },
  {
    title: "Installs",
    dataIndex: "installs",
    align: "right",
    render: (value: number) =>
      value.toLocaleString("en-US", {
        maximumFractionDigits: 0,
      }),
    sorter: {
      compare: (a: DataRow, b: DataRow) => a.installs - b.installs,
      multiple: 6,
    },
  },
  {
    title: "DAU",
    dataIndex: "dau",
    align: "right",
    render: (value: number) =>
      (value || 0).toLocaleString("en-US", {
        maximumFractionDigits: 0,
      }),
    sorter: {
      compare: (a: DataRow, b: DataRow) => a.dau - b.dau,
      multiple: 7,
    },
  },
];

export const dimensionColumns = [
  {
    title: "Date",
    dataIndex: "date",
    sorter: {
      compare: (a: DataRow, b: DataRow) => a.date.localeCompare(b.date),
      multiple: 8,
    },
  },
];
