import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Row, Space, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { useContext, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useQuery, useQueryClient } from "react-query";
import { MainContext } from "../Main";
import { fetchApps } from "../apps/api";
import AssignmentModal from "./AssignmentModal";
import { getPlacementKey } from "./Normalization";
import { fetchPlacements } from "./api";
import { Placement } from "./types";

const PlacementsUnassigned: React.FC = () => {
  const [selectedRows, setSelectedRows] = useState<Placement[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { user } = useContext(MainContext);
  const queryClient = useQueryClient();
  const [filter, setFilter] = useState("");
  const [filterDebounced, setFilterDebounced] = useState("");
  const updateFilter = useMemo(
    () => debounce((filter) => setFilterDebounced(filter), 250),
    []
  );
  const placements = useQuery(
    ["placements", "unassigned"],
    () => fetchPlacements(false),
    {
      onError: (error) => console.log(error),
    }
  );
  const apps = useQuery("apps", fetchApps, {
    onError: (error) => console.log(error),
  });

  const filteredData = useMemo(
    () =>
      placements.data?.filter((placement) => {
        const filterLower = filterDebounced.toLowerCase();
        return (
          placement.id.toLowerCase().includes(filterLower) ||
          placement.provider_id.toLowerCase().includes(filterLower) ||
          placement.platform.toLowerCase().includes(filterLower) ||
          placement.description?.toLowerCase().includes(filterLower) ||
          placement.account.name.toLowerCase().includes(filterLower)
        );
      }),
    [placements.data, filterDebounced]
  );

  const platformsConflict = useMemo(
    () => Array.from(new Set(selectedRows.map((x) => x.platform))).length > 1,
    [selectedRows]
  );

  return (
    <Space style={{ width: "100%" }} direction="vertical">
      {user?.is_admin && (
        <Row justify="end">
          <Space>
            <Tooltip
              title={
                selectedRows.length === 0
                  ? "No rows have been selected"
                  : platformsConflict
                  ? "You can't assign placements from different platforms at the same time"
                  : ""
              }
            >
              <Button
                size="small"
                disabled={
                  selectedRows.length === 0 ||
                  platformsConflict ||
                  placements.isLoading ||
                  apps.isLoading
                }
                onClick={() => {
                  setIsModalVisible(true);
                }}
              >
                Assign selected ({selectedRows.length})
              </Button>
            </Tooltip>

            <Tooltip title="Download CSV file with list of unassigned placements.">
              <CSVLink
                filename={`placements_assigned_${dayjs().format(
                  "YYYY-MM-DD_HH:mm"
                )}.`}
                data={
                  placements.data
                    ? placements.data.map((obj) => ({
                        placement_id: obj.id,
                        account_id: obj.account.id,
                        account_name: obj.account.name,
                        placement_platform: obj.platform,
                        provider_id: obj.provider_id,
                        placement_description: obj.description,
                        installs: obj.installs,
                        revenue: obj.revenue,
                        created_at: obj.created_at,
                        last_visible_at: obj.last_visible_at,
                      }))
                    : []
                }
                className="btn btn-primary"
              >
                <Button size="small" icon={<DownloadOutlined />} />
              </CSVLink>
            </Tooltip>
          </Space>
        </Row>
      )}
      <Input
        disabled={placements.isLoading}
        placeholder="Search by provider, account, id, os or description"
        prefix={<SearchOutlined />}
        value={filter}
        autoFocus
        allowClear
        onChange={(el) => {
          setFilter(el.currentTarget.value);
          updateFilter(el.currentTarget.value);
        }}
        size="large"
      />
      <Table
        bordered
        columns={
          [
            {
              title: "Provider",
              dataIndex: "provider_id",
              filters:
                placements.data === undefined
                  ? []
                  : Array.from(
                      new Set(placements.data.map((row) => row.provider_id))
                    ).map((provider_id) => ({
                      text: provider_id,
                      value: provider_id,
                    })),
              onFilter: (value, record) => record.provider_id === value,
              filterSearch: (input, record) =>
                (record as { text: string; value: string }).value.includes(
                  input
                ),
              sorter: (a, b) => a.provider_id.localeCompare(b.provider_id),
            },
            {
              title: "Account",
              dataIndex: "account",
              render: (value) => value.name,
              filters:
                placements.data === undefined
                  ? []
                  : Array.from(
                      new Set(placements.data.map((row) => row.account.name))
                    ).map((account_name) => ({
                      text: account_name,
                      value: account_name,
                    })),
              onFilter: (value, record) => record.account.name === value,
              filterSearch: (input, record) =>
                (record as { text: string; value: string }).value.includes(
                  input
                ),
              sorter: (a, b) => a.account.name.localeCompare(b.account.name),
            },
            {
              title: "ID",
              dataIndex: "id",
              sorter: (a, b) => (a.id as string).localeCompare(b.id as string),
            },
            {
              title: "OS",
              dataIndex: "platform",
              filters:
                placements.data === undefined
                  ? []
                  : Array.from(
                      new Set(placements.data.map((row) => row.platform))
                    ).map((platform) => ({
                      text: platform,
                      value: platform,
                    })),
              onFilter: (value, record) => record.platform === value,
              filterSearch: (input, record) =>
                (record as { text: string; value: string }).value.includes(
                  input
                ),
              sorter: (a, b) => a.platform.localeCompare(b.platform),
            },
            {
              title: "Description",
              dataIndex: "description",
              sorter: (a, b) =>
                a.description?.localeCompare(b.description || ""),
            },
            {
              title: "Installs",
              dataIndex: "installs",
              sorter: (a, b) => a.installs - b.installs,
            },
            {
              title: "Revenue",
              dataIndex: "revenue",
              sorter: (a, b) => a.revenue - b.revenue,
              render: (value: number) =>
                "$" +
                value.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }),
            },
            {
              title: "Last Visible",
              dataIndex: "last_visible_at",
              sorter: (a, b) =>
                +new Date(a.last_visible_at) - +new Date(b.last_visible_at),
            },
          ] as ColumnsType<Placement>
        }
        size="small"
        dataSource={filteredData}
        rowKey={(record) => getPlacementKey(record)}
        loading={placements.isLoading}
        scroll={{ x: true }}
        rowSelection={
          !user?.is_admin
            ? undefined
            : {
                hideSelectAll: false,
                type: "checkbox",
                selectedRowKeys: selectedRows.map((record) =>
                  getPlacementKey(record)
                ),
                onChange: (
                  selectedRowKeys: React.Key[],
                  selectedRows: Placement[]
                ) => {
                  setSelectedRows(selectedRows);
                },
              }
        }
        onRow={
          !user?.is_admin
            ? undefined
            : (record) => {
                return {
                  onClick: () => {
                    if (selectedRows.includes(record)) {
                      setSelectedRows(
                        selectedRows.filter(
                          (row) =>
                            getPlacementKey(row) !== getPlacementKey(record)
                        )
                      );
                    } else {
                      setSelectedRows([...selectedRows, record]);
                    }
                  },
                };
              }
        }
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          position: ["bottomRight", "topRight"],
        }}
      />
      <AssignmentModal
        setIsOpen={(visible) => setIsModalVisible(visible)}
        isOpen={isModalVisible}
        data={selectedRows}
        apps={apps.data || []}
        onSuccess={(responseData) => {
          placements.data &&
            queryClient.setQueryData(
              ["placements", "unassigned"],
              placements.data.filter(
                (obj) =>
                  !responseData
                    .map((x) => getPlacementKey(x))
                    .includes(getPlacementKey(obj))
              )
            );
          queryClient.invalidateQueries(["placements", "assigned"]);
          setSelectedRows([]);
        }}
      />
    </Space>
  );
};

export default PlacementsUnassigned;
