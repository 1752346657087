import { Line } from "@ant-design/plots";
import _ from "lodash";
import { useMemo } from "react";
import { QueryResponse } from "../types";

interface LineChartProps {
  data: QueryResponse;
  seriesAmount: number;
  selectedMetric: string;
}

export const LineChart: React.FC<LineChartProps> = (props) => {
  const metricsWithoutDollar = ["installs", "updates", "dau"];

  const dimension = useMemo(() => {
    return props.data.dimensions.filter((val) => val !== "date")[0];
  }, [props.data.dimensions]);

  const topRows = useMemo(() => {
    const grouped = _(props.data.data)
      .groupBy(dimension)
      .map((obj) => {
        return {
          [dimension]: obj[0][dimension],
          [props.selectedMetric]: _.sumBy(obj, props.selectedMetric),
        };
      })
      .sort(
        (a, b) =>
          ((b[props.selectedMetric] as number) || 0) -
          ((a[props.selectedMetric] as number) || 0)
      )
      .map((obj) => obj[dimension])
      .splice(0, props.seriesAmount - 1)
      .value();
    return grouped;
  }, [props.data, props.selectedMetric, props.seriesAmount]);

  const transformedData = useMemo(() => {
    const other = _(
      props.data.data
        .filter((obj) => !topRows.includes(obj[dimension]))
        .map((obj) => ({ ...obj, [dimension]: "Other" }))
    )
      .groupBy("date")
      .map((obj) => {
        return {
          date: obj[0].date,
          [dimension]: obj[0][dimension],
          [props.selectedMetric]: _.sumBy(obj, props.selectedMetric),
        };
      })
      .sort(
        (a, b) =>
          ((b[props.selectedMetric] as number) || 0) -
          ((a[props.selectedMetric] as number) || 0)
      )
      .value();

    return [
      ...props.data.data
        .filter((obj) => topRows.includes(obj[dimension]))
        .map((obj) => ({
          date: obj.date,
          [dimension]: obj[dimension],
          [props.selectedMetric]: obj[props.selectedMetric],
        })),
      ...other,
    ].sort((a, b) => {
      if (a.date === b.date) {
        return (
          ((b[props.selectedMetric] as number) || 0) -
          ((a[props.selectedMetric] as number) || 0)
        );
      } else {
        return a.date.localeCompare(b.date);
      }
    });
  }, [props.data, topRows]);

  return (
    <Line
      legend={{
        position: "right-top",
      }}
      data={transformedData}
      xField="date"
      yField={props.selectedMetric}
      seriesField={dimension}
      yAxis={{
        label: {
          formatter: (datum) =>
            metricsWithoutDollar.includes(props.selectedMetric)
              ? parseInt(datum).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })
              : "$" +
                parseInt(datum).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                }),
        },
      }}
      tooltip={{
        customItems: (originalItems) => {
          originalItems = originalItems.sort(
            (a, b) =>
              b.data[props.selectedMetric] - a.data[props.selectedMetric]
          );
          return originalItems;
        },
        formatter: (datum) => {
          return {
            name: datum[dimension],
            value: metricsWithoutDollar.includes(props.selectedMetric)
              ? datum[props.selectedMetric].toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : "$" +
                datum[props.selectedMetric].toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
          };
        },
      }}
    />
  );
};
