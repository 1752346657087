import { DatePicker } from "antd";
import { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { rangePickerPresets } from "./rangePickerPresets";

const { RangePicker } = DatePicker;

interface DashboardRangePickerProps {
  values: [Dayjs, Dayjs];
  onChange: (newDates: [Dayjs, Dayjs]) => void;
}

export const DashboardRangePicker: React.FC<DashboardRangePickerProps> = (
  props
) => {
  const [values, setValues] = useState<[Dayjs, Dayjs]>(props.values);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isOpen && values && values[0] && values[1]) {
      props.onChange(values);
    }
  }, [values, isOpen]);

  return (
    <RangePicker
      onOpenChange={setIsOpen}
      allowClear={false}
      disabledDate={(current: Dayjs) =>
        current.year() > 2022 || current.year() < 2022
      }
      onCalendarChange={(newValues) =>
        newValues !== null &&
        newValues[0] !== null &&
        newValues[1] !== null &&
        setValues([newValues[0], newValues[1]])
      }
      value={values}
      presets={rangePickerPresets}
    />
  );
};
