import { DualAxes } from "@ant-design/plots";
import dayjs from "dayjs";
import { groupBy } from "lodash";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { ApiError } from "../../utils/types";
import { fetchDashboardData } from "../api";
import { QueryResponse } from "../types";

interface ComboChartProps {
  data: QueryResponse;
  seriesAmount: number;
}

export const ComboChart: React.FC<ComboChartProps> = (props) => {
  const dashboardData = useQuery(
    ["dashboard_data", "combo_chart"],
    () =>
      fetchDashboardData({
        date_start: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
        date_end: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
        filters: {},
        time_granularity: "daily",
        dimensions: ["date", "provider_id"],
      }),
    {
      onError: (error: ApiError) => {
        console.log(error);
      },
    }
  );
  const dimension = useMemo(
    () =>
      props.data.dimensions.length === 1
        ? props.data.dimensions[0]
        : props.data.dimensions.filter((val) => val !== "date")[0],
    [props.data.dimensions]
  );

  const lastFullInstallsDate = useMemo(() => {
    if (dashboardData.data && dimension === "date") {
      const providers = Array.from(
        new Set(
          dashboardData.data.data
            .filter((row) => row.installs > 0)
            .map((row) => row.provider_id)
        )
      );
      const grouped = Object.fromEntries(
        Object.entries(groupBy(dashboardData.data.data, "date")).map(
          ([date, rows]) => {
            const rowsFiltered = rows.filter(
              (row) => providers.includes(row.provider_id) && row.installs > 0
            );
            return [date, rowsFiltered.length];
          }
        )
      );
      const lastDate = Math.max(
        ...Object.entries(grouped)
          .filter(
            ([date, count]) => count === Math.max(...Object.values(grouped))
          )
          .map(([date, count]) => dayjs(date).valueOf())
      );
      return dayjs(lastDate);
    } else {
      return undefined;
    }
  }, [dashboardData.data, dimension]);

  const topRows = useMemo(() => {
    if (props.data.data.length < props.seriesAmount) {
      return props.data.data.map((obj) => obj[dimension]);
    } else {
      if (dimension !== "date") {
        return props.data.data
          .sort((a, b) => b.total_revenue_net - a.total_revenue_net)
          .map((obj) => obj[dimension])
          .slice(0, props.seriesAmount);
      } else {
        return props.data.data
          .sort((a, b) => b.date.localeCompare(a.date))
          .map((obj) => obj[dimension]);
      }
    }
  }, [props.data, props.seriesAmount]);

  const filteredData = useMemo(
    () => props.data.data.filter((obj) => topRows.includes(obj[dimension])),
    [topRows]
  );

  const sortedData = useMemo(
    () =>
      filteredData.sort((a, b) => {
        if (dimension === "date") {
          return a.date!.toString().localeCompare(b.date!.toString());
        } else {
          return b.total_revenue_net - a.total_revenue_net;
        }
      }),
    [filteredData]
  );

  const dataTransformed = useMemo(
    () => [
      [
        ...sortedData.map((obj) => ({
          [dimension]: obj[dimension],
          revenue: obj.sub_revenue_net,
          type: "SUB Revenue Net",
        })),
        ...sortedData.map((obj) => ({
          [dimension]: obj[dimension],
          revenue: obj.iap_revenue_net,
          type: "IAP Revenue Net",
        })),
        ...sortedData.map((obj) => ({
          [dimension]: obj[dimension],
          revenue: obj.ad_revenue,
          type: "Ad Revenue",
        })),
      ],
      sortedData.map((obj) => ({
        [dimension]: obj[dimension],
        installs: obj.installs,
        type: "Installs",
      })),
    ],
    [sortedData]
  );

  return (
    <DualAxes
      data={dataTransformed}
      xField={dimension}
      yField={["revenue", "installs"]}
      legend={{
        position: "bottom",
      }}
      yAxis={{
        revenue: {
          label: {
            formatter: (datum) =>
              "$" +
              parseInt(datum).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              }),
          },
        },
        installs: {
          min: 0,
          label: {
            formatter: (datum) =>
              parseInt(datum).toLocaleString("en-US", {
                maximumFractionDigits: 0,
              }),
          },
        },
      }}
      tooltip={{
        customItems: (originalItems) => {
          originalItems = originalItems.sort(
            (a, b) => b.data.revenue - a.data.revenue
          );
          return originalItems;
        },
        formatter: (datum) => {
          return {
            name:
              datum.type ||
              (lastFullInstallsDate && dayjs(datum.date) > lastFullInstallsDate
                ? "Installs (partial)"
                : "Installs"),
            value: datum.revenue
              ? "$" +
                datum.revenue.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : (datum.installs || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
          };
        },
      }}
      annotations={
        lastFullInstallsDate && {
          installs: [
            {
              type: "regionFilter",
              start: [lastFullInstallsDate!.format("YYYY-MM-DD"), "start"],
              end: ["end", "end"],
              color: "#ffe58f",
            },
          ],
        }
      }
      geometryOptions={[
        {
          geometry: "column",
          isStack: true,
          seriesField: "type",
        },
        {
          geometry: "line",
        },
      ]}
    />
  );
};
