import { SearchOutlined } from "@ant-design/icons";
import { Card, Input, Table } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Platform } from "../apps/types";
import { fetchAccountsPlacements } from "./api";

interface PlacementTableProps {
  account_id: number;
}

const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "OS",
    dataIndex: "platform",
  },
  {
    title: "Description",
    dataIndex: "description",
  },
  {
    title: "App & Platform",
    dataIndex: "assigned_platform",
    render: (value: Platform) =>
      value ? value.app?.name + " " + value.name : "",
  },
];

const PlacementTable: React.FC<PlacementTableProps> = (props) => {
  const [filter, setFilter] = useState("");

  const placements = useQuery(
    ["placements", props.account_id],
    () => fetchAccountsPlacements(props.account_id.toString()),
    {
      onError: (error) => console.log(error),
    }
  );

  useEffect(() => {
    setFilter("");
  }, [placements.data]);

  const dataFiltered = useMemo(() => {
    return placements.data === undefined || filter === ""
      ? placements.data
      : placements.data.filter((placement) => {
          const filterLower = filter.toLowerCase();
          return (
            placement.id.toString().includes(filterLower) ||
            placement.provider_id.toLowerCase().includes(filterLower) ||
            placement.description?.toLowerCase().includes(filterLower) ||
            (placement.assigned_platform &&
              placement.assigned_platform.app &&
              placement.assigned_platform.app.name
                .toLowerCase()
                .includes(filterLower)) ||
            (placement.assigned_platform &&
              placement.assigned_platform.name
                .toLowerCase()
                .includes(filterLower))
          );
        });
  }, [placements.data, filter]);

  return (
    <Card title="Placements">
      <Input
        placeholder="Search by placement ID, provider ID, description, App name or App platform."
        prefix={<SearchOutlined />}
        value={filter}
        autoFocus
        allowClear
        onChange={(el) => setFilter(el.currentTarget.value)}
        disabled={placements.isLoading}
        size="small"
      />
      <Table
        bordered
        loading={placements.isLoading}
        dataSource={dataFiltered}
        rowKey={(val) =>
          `${val.id}-${val.platform}-${val.assigned_platform?.app_id}`
        }
        size="small"
        columns={columns}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          position: ["bottomRight", "topRight"],
        }}
      />
    </Card>
  );
};

export default PlacementTable;
