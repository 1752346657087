import { Breadcrumb, Space, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Alignment } from "./types";

export const PageTitle: React.FC<{
  title: string;
  breadcrumbs?: { label: string; route?: string }[];
  extra?: React.ReactNode;
  align?: Alignment;
}> = ({ title, breadcrumbs, extra, align }) => {
  return (
    <div style={{ margin: "1em 0 0.5em 0" }}>
      <Breadcrumb>
        {[{ label: "Home", route: "/" }, ...(breadcrumbs || [])].map(
          ({ label, route }, i) => (
            <Breadcrumb.Item key={i}>
              {route ? <Link to={route}>{label}</Link> : label}
            </Breadcrumb.Item>
          )
        )}
      </Breadcrumb>
      <Space
        style={{
          width: "100%",
          justifyContent: "space-between",
        }}
        align={align || "end"} // TODO: nie wiem czy end czy start. Imo z end wygląda lepiej
      >
        <Typography.Title level={2} style={{ margin: "14px 0px 0px 0px" }}>
          {title}
        </Typography.Title>
        {extra}
      </Space>
    </div>
  );
};
