import axios from "axios";
import fileDownload from "js-file-download";
import { App } from "./types";

export const fetchApps = (): Promise<App[]> =>
  axios.get("/api/v1/apps").then((response) => response.data);

export const fetchAppsCsv = () =>
  axios.get("/api/v1/utils/apps", { responseType: "blob" }).then((response) => {
    fileDownload(response.data, "dashboard_apps_list.csv");
  });

export const fetchApp = (appId: string): Promise<App> =>
  axios.get(`/api/v1/apps/${appId}`).then((response) => response.data);
