import { InfoCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Checkbox, DatePicker, Select, Space, Tooltip } from "antd";
import dayjs from "dayjs";
import { isEqual } from "lodash";
import { useEffect, useMemo } from "react";
import { filterEmptyQueryFilters } from "../../utils/UtilsFunctions";
import { DashboardFilters } from "../filters/DashboardFilters";
import { QueryFilters } from "../types";
import { EstimationsResponse } from "./types";

interface ControlPanelProps {
  isLoading: boolean;
  isBtnDisabled: boolean;
  dateRange: dayjs.Dayjs[];
  isBackInTimeEnabled: boolean;
  filters: QueryFilters;
  verifyDays: number;
  trainingDays: number;
  currentData?: EstimationsResponse;
  selectedMetric: string;
  setVerifyDays: (days: number) => void;
  setTrainingDays: (days: number) => void;
  setFilters: (newFilters: QueryFilters) => void;
  deleteFilter: (filter: string) => void;
  addFilter: (filter: string) => void;
  onBtnClick: () => void;
  setDateRange: (x: dayjs.Dayjs[]) => void;
  setIsBackInTimeEnabled: (x: boolean) => void;
}
const { RangePicker } = DatePicker;

export const ControlPanel: React.FC<ControlPanelProps> = (props) => {
  const visibleFilters = useMemo(
    () =>
      Object.entries(props.filters).map((filter) => {
        return filter[0];
      }),
    [props.filters]
  );

  useEffect(() => {
    const isBit = props.isBackInTimeEnabled;
    const date0 = props.dateRange[0];
    const date1 = props.dateRange[1];
    let destDate0 = date0;
    let destDate1 = date1;

    if (isBit) {
      if (
        dayjs()
          .subtract(props.verifyDays + props.trainingDays, "days")
          .diff(props.dateRange[0], "days") !== 0
      ) {
        destDate0 = dayjs().subtract(
          props.verifyDays + props.trainingDays,
          "days"
        );
      }
      if (props.dateRange[1] > dayjs()) {
        destDate1 = dayjs().subtract(1, "days");
      }
    } else if (!isBit) {
      if (props.selectedMetric === "installs") {
        if (props.dateRange[0] < dayjs().subtract(35, "days")) {
          destDate0 = dayjs().subtract(35, "days");
        }
        if (props.dateRange[1] < dayjs()) {
          destDate1 = dayjs().add(2, "days");
        }
      } else {
        if (props.dateRange[0] < dayjs().subtract(32, "days")) {
          destDate0 = dayjs().subtract(32, "days");
        }
        if (props.dateRange[1] < dayjs()) {
          destDate1 = dayjs().add(5, "days");
        }
      }
    }
    props.setDateRange([destDate0, destDate1]);
  }, [
    props.isBackInTimeEnabled,
    props.verifyDays,
    props.trainingDays,
    props.selectedMetric,
  ]);

  const isQueryDifferent = useMemo(() => {
    return !isEqual(
      {
        dateStart: props.dateRange[0].format("YYYY-MM-DD"),
        dateEnd: props.dateRange[1].format("YYYY-MM-DD"),
        filters: filterEmptyQueryFilters(props.filters),
        backInTime: props.isBackInTimeEnabled,
        ...(props.isBackInTimeEnabled && {
          verifyDays: props.verifyDays,
          trainingDays: props.trainingDays,
        }),
      },
      {
        dateStart: props.currentData && props.currentData.date_start,
        dateEnd: props.currentData && props.currentData.date_end,
        filters:
          props.currentData &&
          filterEmptyQueryFilters(props.currentData.filters),
        backInTime: props.currentData?.back_in_time !== null,
        ...(props.currentData !== undefined &&
          props.currentData?.back_in_time !== null && {
            verifyDays: props.currentData.back_in_time.days,
            trainingDays: props.currentData.training_days,
          }),
      }
    );
  }, [
    props.filters,
    props.dateRange,
    props.verifyDays,
    props.trainingDays,
    props.currentData,
  ]);

  return (
    <Space direction="vertical">
      <Space>
        <Space align="end">
          <Button
            type={isQueryDifferent ? "primary" : "default"}
            icon={<ReloadOutlined />}
            loading={props.isLoading}
            disabled={props.isBtnDisabled}
            onClick={props.onBtnClick}
          >
            Reload
          </Button>
          <RangePicker
            disabled={props.isLoading}
            disabledDate={(current) =>
              props.isBackInTimeEnabled
                ? current > dayjs().subtract(1, "days")
                : false
            }
            allowEmpty={[false, false]}
            onChange={(e) => {
              if (e !== null && e[0] !== null && e[1] !== null) {
                props.setDateRange([dayjs(e[0]), dayjs(e[1])]);
              }
            }}
            value={[props.dateRange[0], props.dateRange[1]]}
            presets={[
              {
                label: "Next 7 days",
                value: [dayjs().subtract(2, "d"), dayjs().add(5, "d")],
              },
              {
                label: "Next 14 days",
                value: [dayjs().subtract(2, "d"), dayjs().add(12, "d")],
              },
              {
                label: "Next 30 days",
                value: [dayjs().subtract(2, "d"), dayjs().add(28, "d")],
              },
            ]}
          />
        </Space>
      </Space>
      <DashboardFilters
        isLoading={props.isLoading}
        filters={props.filters}
        setFilters={props.setFilters}
        deleteFilter={props.deleteFilter}
      />
      <Space style={{ lineHeight: 2 }}>
        <Checkbox
          disabled={props.isLoading}
          checked={props.isBackInTimeEnabled}
          onChange={(e) => props.setIsBackInTimeEnabled(e.target.checked)}
        >
          Back In Time™
        </Checkbox>
        <Tooltip title="With this option selected, you can simulate creating estimations in the past and compare it with real data.">
          <InfoCircleOutlined />
        </Tooltip>
        {props.isBackInTimeEnabled && (
          <>
            Verify last
            <Select
              disabled={props.isLoading}
              style={{ width: "100px" }}
              onChange={props.setVerifyDays}
              allowClear={false}
              value={props.verifyDays}
              size="small"
              options={[
                {
                  label: "7 days",
                  value: 7,
                },
                {
                  label: "14 days",
                  value: 14,
                },
                {
                  label: "30 days",
                  value: 30,
                },
              ]}
            />
            Train for
            <Select
              disabled={props.isLoading}
              style={{ width: "100px" }}
              onChange={props.setTrainingDays}
              allowClear={false}
              value={props.trainingDays}
              size="small"
              options={[
                {
                  label: "30 days",
                  value: 30,
                },
                {
                  label: "60 days",
                  value: 60,
                },
                {
                  label: "90 days",
                  value: 90,
                },
              ]}
            />
          </>
        )}
      </Space>
    </Space>
  );
};
