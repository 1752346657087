import axios from "axios";
import { ApiToken, AutocompleteValues } from "./types";

export const fetchAutocompleteValues = (
  field: string
): Promise<AutocompleteValues[]> =>
  axios
    .get(`/api/v1/utils/autocomplete/${field}`)
    .then((response) =>
      response.data.sort((a: AutocompleteValues, b: AutocompleteValues) =>
        a.label.localeCompare(b.label)
      )
    );

export const fetchApiTokens = (): Promise<ApiToken[]> =>
  axios
    .get("/api/v1/admin/users/me/api-tokens")
    .then((response) => response.data);

export const createApiToken = (): Promise<ApiToken[]> =>
  axios
    .post("/api/v1/admin/users/me/api-tokens")
    .then((response) => response.data);
