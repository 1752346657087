import { InfoCircleOutlined } from "@ant-design/icons";
import { Space, Tooltip, Typography } from "antd";
import { useMemo } from "react";
import { metricsWithoutDollar } from "../Dashboard";

const { Text } = Typography;

interface ChartMetricsProps {
  estimatedSum: number;
  realSum: number;
  accuracy: number;
  mae: number;
  metric: string;
}

export const ChartMetrics: React.FC<ChartMetricsProps> = (props) => {
  const digits = useMemo(() => {
    return metricsWithoutDollar.includes(props.metric) ? 0 : 2;
  }, [props.metric]);

  const estToRealDiff = useMemo(() => {
    const val = (props.estimatedSum / props.realSum) * 100 - 100;
    return (
      <span>
        {"("}
        <Text
          style={{
            color: (val | 0) > 0 ? "green" : val < 0 ? "red" : "",
          }}
        >
          {val > 0 ? "+" : ""}
          {val.toLocaleString("en-US", {
            maximumFractionDigits: 0,
          })}
          %
        </Text>
        {")"}
      </span>
    );
  }, [props.estimatedSum, props.realSum]);

  return (
    <Space direction="vertical">
      <Space.Compact direction="vertical">
        <span>
          <Text type="secondary">MAE</Text>{" "}
          <Text type="secondary">
            <Tooltip title="Mean absolute error - measure of errors between estimated and real data. It's calculated as a mean value of |Y - X| where Y is estimated and X is real.">
              <InfoCircleOutlined />
            </Tooltip>
          </Text>
        </span>
        <Text style={{ fontSize: 16, fontWeight: 300 }}>
          {digits > 0 ? "$" : ""}
          {props.mae.toLocaleString("en-US", { maximumFractionDigits: digits })}
        </Text>
      </Space.Compact>

      <Space.Compact direction="vertical">
        <span>
          <Text type="secondary">Accuracy</Text>{" "}
          <Text type="secondary">
            <Tooltip title="Accuracy - it's calculated as 1 - |1 - (Y / X)|. Y = sum of estimated data, X = sum of real data for the same date range as estimated.">
              <InfoCircleOutlined />
            </Tooltip>
          </Text>
        </span>
        <Text style={{ fontSize: 16, fontWeight: 300 }}>
          {(props.accuracy * 100).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          %
        </Text>
      </Space.Compact>

      <Space.Compact direction="vertical">
        <Text type="secondary">Real data</Text>
        <Text style={{ fontSize: 16, fontWeight: 300 }}>
          {digits > 0 ? "$" : ""}
          {props.realSum.toLocaleString("en-US", {
            maximumFractionDigits: 0,
          })}
        </Text>
      </Space.Compact>

      <Space.Compact direction="vertical">
        <Text type="secondary">Estimated</Text>
        <span>
          <Text style={{ fontSize: 16, fontWeight: 300 }}>
            {digits > 0 ? "$" : ""}
            {props.estimatedSum.toLocaleString("en-US", {
              maximumFractionDigits: 0,
            })}{" "}
          </Text>
          {estToRealDiff}
        </span>
      </Space.Compact>
    </Space>
  );
};
