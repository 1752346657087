import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Row, Space, Table, Tooltip, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { useContext, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { MainContext } from "../Main";
import { handleApiError } from "../utils/ApiErrorHandler";
import { getPlacementKey } from "./Normalization";
import { fetchPlacements, unassignPlacements } from "./api";
import { Placement } from "./types";

const PlacementsAssigned: React.FC = () => {
  const [selectedRows, setSelectedRows] = useState<Placement[]>([]);
  const { user } = useContext(MainContext);
  const queryClient = useQueryClient();
  const [filter, setFilter] = useState("");
  const [filterDebounced, setFilterDebounced] = useState("");
  const updateFilter = useMemo(
    () => debounce((filter) => setFilterDebounced(filter), 250),
    []
  );
  const placements = useQuery(
    ["placements", "assigned"],
    () => fetchPlacements(true),
    {
      onError: (error) => console.log(error),
    }
  );
  const placementsMutation = useMutation({
    mutationFn: () =>
      unassignPlacements(
        selectedRows.map((placement) => ({
          id: placement.id,
          platform: placement.platform,
          provider_id: placement.provider_id,
        }))
      ),
    onError: (error) => {
      error && handleApiError(error);
    },
    onSuccess: () => {
      message.success("Success");
      setSelectedRows([]);
      queryClient.setQueriesData(
        ["placements", "assigned"],
        placements.data &&
          placements.data.filter(
            (row) =>
              !selectedRows
                .map((obj) => getPlacementKey(obj))
                .includes(getPlacementKey(row))
          )
      );
      queryClient.invalidateQueries(["placements", "unassigned"]);
    },
  });

  const filteredData = useMemo(
    () =>
      placements.data?.filter((placement) => {
        const filterLower = filterDebounced.toLowerCase();
        return (
          placement.id.toLowerCase().includes(filterLower) ||
          placement.provider_id.toLowerCase().includes(filterLower) ||
          placement.platform.toLowerCase().includes(filterLower) ||
          placement.description?.toLowerCase().includes(filterLower) ||
          placement.account.name.toLowerCase().includes(filterLower)
        );
      }),
    [placements.data, filterDebounced]
  );

  return (
    <Space style={{ width: "100%" }} direction="vertical">
      {user?.is_admin && (
        <Row justify="end">
          <Space>
            <Tooltip
              title={
                selectedRows.length === 0 ? "No rows have been selected" : ""
              }
            >
              <Button
                size="small"
                disabled={selectedRows.length === 0 || placements.isLoading}
                onClick={() => placementsMutation.mutate()}
              >
                Unassign selected ({selectedRows.length})
              </Button>
            </Tooltip>
            <Tooltip title="Download CSV file with list of assigned placements.">
              <CSVLink
                filename={`placements_unassigned_${dayjs().format(
                  "YYYY-MM-DD_HH:mm"
                )}.`}
                data={
                  placements.data
                    ? placements.data?.map((obj) => ({
                        placement_id: obj.id,
                        account_id: obj.account.id,
                        account_name: obj.account.name,
                        platform_id: obj.platform_id,
                        placement_platform: obj.platform,
                        app_id: obj.assigned_platform.app_id,
                        app_name: obj.assigned_platform.app?.name,
                        provider_id: obj.provider_id,
                        placement_description: obj.description,
                        installs: obj.installs,
                        revenue: obj.revenue,
                        created_at: obj.created_at,
                        assigned_at: obj.assigned_at,
                        assigned_by: obj.assigned_by,
                        last_visible_at: obj.last_visible_at,
                      }))
                    : []
                }
                className="btn btn-primary"
              >
                <Button size="small" icon={<DownloadOutlined />} />
              </CSVLink>
            </Tooltip>
          </Space>
        </Row>
      )}
      <Input
        disabled={placements.isLoading}
        placeholder="Search by provider, account, id, os or description"
        prefix={<SearchOutlined />}
        value={filter}
        autoFocus
        allowClear
        onChange={(el) => {
          setFilter(el.currentTarget.value);
          updateFilter(el.currentTarget.value);
        }}
        size="large"
      />
      <Table
        bordered
        columns={
          [
            {
              title: "Provider",
              dataIndex: "provider_id",
              filters:
                placements.data === undefined
                  ? []
                  : Array.from(
                      new Set(placements.data.map((row) => row.provider_id))
                    ).map((provider_id) => ({
                      text: provider_id,
                      value: provider_id,
                    })),
              onFilter: (value, record) => record.provider_id === value,
              filterSearch: (input, record) =>
                (record as { text: string; value: string }).value.includes(
                  input
                ),
              sorter: (a, b) => a.provider_id.localeCompare(b.provider_id),
            },
            {
              title: "Account",
              dataIndex: "account",
              render: (value) => value.name,
              filters:
                placements.data === undefined
                  ? []
                  : Array.from(
                      new Set(placements.data.map((row) => row.account.name))
                    ).map((account_name) => ({
                      text: account_name,
                      value: account_name,
                    })),
              onFilter: (value, record) => record.account.name === value,
              filterSearch: (input, record) =>
                (record as { text: string; value: string }).value.includes(
                  input
                ),
              sorter: (a, b) => a.account.name.localeCompare(b.account.name),
            },
            {
              title: "ID",
              dataIndex: "id",
              sorter: (a, b) => (a.id as string).localeCompare(b.id as string),
            },
            {
              title: "OS",
              dataIndex: "platform",
              filters:
                placements.data === undefined
                  ? []
                  : Array.from(
                      new Set(placements.data.map((row) => row.platform))
                    ).map((platform) => ({
                      text: platform,
                      value: platform,
                    })),
              onFilter: (value, record) => record.platform === value,
              filterSearch: (input, record) =>
                (record as { text: string; value: string }).value.includes(
                  input
                ),
              sorter: (a, b) => a.platform.localeCompare(b.platform),
            },
            {
              title: "Description",
              dataIndex: "description",
              sorter: (a, b) =>
                a.description?.localeCompare(b.description || ""),
            },
            {
              title: "Assigned App",
              dataIndex: "assigned_platform",
              render: (value, record) => (
                <Link to={`/apps/${record.assigned_platform.app!.id}`}>
                  {value.app.name} {record.platform}
                </Link>
              ),
              sorter: (a, b) =>
                `${a.assigned_platform.app!.name} ${a.platform}`.localeCompare(
                  `${b.assigned_platform.app!.name} ${b.platform}`
                ),
            },
          ] as ColumnsType<Placement>
        }
        size="small"
        dataSource={filteredData}
        rowKey={(record) => getPlacementKey(record)}
        loading={placements.isLoading}
        scroll={{ x: true }}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          position: ["bottomRight", "topRight"],
        }}
        rowSelection={
          !user?.is_admin
            ? undefined
            : {
                hideSelectAll: false,
                type: "checkbox",
                selectedRowKeys: selectedRows.map((record) =>
                  getPlacementKey(record)
                ),
                onChange: (
                  selectedRowKeys: React.Key[],
                  selectedRows: Placement[]
                ) => {
                  setSelectedRows(selectedRows);
                },
              }
        }
        onRow={
          !user?.is_admin
            ? undefined
            : (record) => {
                return {
                  onClick: () => {
                    if (selectedRows.includes(record)) {
                      setSelectedRows(
                        selectedRows.filter(
                          (row) =>
                            getPlacementKey(row) !== getPlacementKey(record)
                        )
                      );
                    } else {
                      setSelectedRows([...selectedRows, record]);
                    }
                  },
                };
              }
        }
      />
    </Space>
  );
};

export default PlacementsAssigned;
