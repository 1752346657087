import { SaveOutlined } from "@ant-design/icons";
import {
  Alert,
  AutoComplete,
  Button,
  Card,
  Form,
  Input,
  message,
  Row,
  Space,
  Spin,
  Switch,
  Tooltip,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { AxiosError } from "axios";
import React, { useContext, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { MainContext } from "../Main";
import { fetchAutocompleteValues } from "../utils/api";
import { handleApiError } from "../utils/ApiErrorHandler";
import { Page } from "../utils/Page";
import { PageTitle } from "../utils/PageTitle";
import { fetchAccount, fetchProviders, patchAccount } from "./api";
import PlacementTable from "./PlacementsTable";

const AccountDetails: React.FC = () => {
  const { user } = useContext(MainContext);

  const navigate = useNavigate();
  const params = useParams();
  const param_account_id = params.id;
  const queryClient = useQueryClient();
  const providers = useQuery("providers", fetchProviders);
  const accountPatchMutation = useMutation({
    mutationFn: (formValues: any) => {
      let credentials: { [x: string]: any } = {};
      for (let key of Object.keys(formValues).filter(
        (key) => key !== "description"
      )) {
        if (
          ![
            "name",
            "bank_account",
            "provider_id",
            "is_active",
            "provider",
          ].includes(key)
        ) {
          credentials[key] = formValues[key];
        }
      }
      formValues = { ...formValues, credentials: credentials };
      return patchAccount(account.data!.id.toString(), formValues);
    },
    onError: (error) => {
      error && handleApiError(error);
    },
    onSuccess: (response) => {
      console.log(response);
      queryClient.setQueryData(["accounts", param_account_id], response);
      queryClient.invalidateQueries("accounts");
      message.success("Success");
    },
  });
  const account = useQuery(
    ["accounts", param_account_id],
    () => fetchAccount(param_account_id || "0"),
    {
      onError: (error: AxiosError) => {
        console.log(error);
        error.response !== undefined &&
          message.error(
            error.response.status === 403
              ? "You have no access to this account."
              : "Encoured an error."
          );

        navigate("accounts");
      },
    }
  );
  const bankAccounts = useQuery(
    ["bank_account", "autocomplete"],
    () => fetchAutocompleteValues("bank_account"),
    {
      enabled: false,
    }
  );

  const description = useMemo(() => {
    if (providers.data && account.data) {
      const description = providers.data.find(
        (provider) => provider.id === account.data.provider_id
      )?.description;
      return description ? (
        <Alert
          type="info"
          closable={false}
          message={
            <div
              dangerouslySetInnerHTML={{
                __html:
                  providers.data.find(
                    (provider) => provider.id === account.data.provider_id
                  )?.description || "",
              }}
            />
          }
        />
      ) : undefined;
    } else {
      return undefined;
    }
  }, [providers.data, account.data?.provider_id]);

  return (
    <>
      {!account.data ? (
        <Row justify={"center"}>
          <Spin size="large" tip="Loading..." style={{ marginTop: "100px" }} />
        </Row>
      ) : (
        <>
          <Page>
            <PageTitle
              title={`Account ${account.data.name}`}
              breadcrumbs={[
                { label: "Accounts", route: "/accounts" },
                { label: account.data.name },
              ]}
            />
            <Card>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Form
                  onFinish={accountPatchMutation.mutate}
                  labelCol={{ span: 3 }}
                  wrapperCol={{ span: 21 }}
                  disabled={accountPatchMutation.isLoading}
                >
                  <Form.Item
                    label="Provider"
                    name="provider"
                    initialValue={account.data.provider_id}
                  >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please provide name of account",
                      },
                    ]}
                    initialValue={account.data.name}
                  >
                    <Input disabled={!user?.is_admin} />
                  </Form.Item>
                  <Form.Item
                    label="Bank Account"
                    name="bank_account"
                    rules={[
                      {
                        required: true,
                        message: "Please provide bank account",
                      },
                    ]}
                    initialValue={account.data.bank_account}
                  >
                    <AutoComplete
                      options={bankAccounts.data}
                      onDropdownVisibleChange={() =>
                        bankAccounts.data === undefined &&
                        bankAccounts.refetch()
                      }
                      disabled={!user?.is_admin}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Enabled"
                    name="is_active"
                    valuePropName="checked"
                    initialValue={account.data.is_active}
                  >
                    <Switch disabled={!user?.is_admin} />
                  </Form.Item>
                  {user?.is_admin && description && (
                    <Form.Item label="Description">{description}</Form.Item>
                  )}
                  {user?.is_admin &&
                    providers.data &&
                    providers.data
                      .find(
                        (provider) => provider.id === account.data.provider_id
                      )
                      ?.fields.filter((field) => field.key !== "description")
                      .map((field) => (
                        <Form.Item
                          key={field.key}
                          label={field.name}
                          name={field.key}
                          rules={[
                            {
                              required: true,
                              message: `Please provide ${field.name}`,
                            },
                          ]}
                          initialValue={account.data.credentials[field.key]}
                        >
                          {field.type === "text" ? (
                            <Input placeholder={field.placeholder} />
                          ) : (
                            <TextArea
                              disabled={accountPatchMutation.isLoading}
                              placeholder={field.placeholder}
                            />
                          )}
                        </Form.Item>
                      ))}
                  <Form.Item>
                    <Tooltip
                      title={
                        !user?.is_admin &&
                        "You have no permission to edit account"
                      }
                    >
                      <Button
                        type="primary"
                        icon={<SaveOutlined />}
                        htmlType="submit"
                        disabled={!user?.is_admin}
                      >
                        Save
                      </Button>
                    </Tooltip>
                  </Form.Item>
                </Form>
              </Space>
            </Card>
            <PlacementTable account_id={account.data.id} />
          </Page>
        </>
      )}
    </>
  );
};

export default AccountDetails;
