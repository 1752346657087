import { Card } from "antd";
import { useMemo } from "react";
import { dimensionColumns } from "../dashboardColumns";
import { QueryFilters } from "../types";
import { DashboardFilterSelect } from "./DashboardFilterSelect";

interface DashboardFiltersProps {
  filters: QueryFilters;
  isLoading: boolean;
  setFilters: (newFilters: QueryFilters) => void;
  deleteFilter: (filter: string) => void;
}

export const DashboardFilters: React.FC<DashboardFiltersProps> = (props) => {
  const visibleFilters = useMemo(
    () =>
      Object.entries(props.filters).map((filter) => {
        return filter[0];
      }),
    [props.filters]
  );

  return (
    <>
      <Card size="small">
        {dimensionColumns
          .filter(
            (col) => col.dataIndex !== "date" && props.filters[col.dataIndex]
          )
          .map((col) => (
            <Card.Grid
              key={col.dataIndex}
              hoverable={true}
              style={{
                // minWidth: "175px",
                maxWidth: "400px",
                textAlign: "left",
                width: "auto",
                padding: "0px 0px",
              }}
            >
              <DashboardFilterSelect
                key={col.dataIndex}
                index={col.dataIndex}
                filter={
                  props.filters[col.dataIndex] || {
                    value: [],
                    type: "include",
                  }
                }
                label={col.title}
                disabled={props.isLoading}
                isVisible={visibleFilters.includes(col.dataIndex)}
                onChange={(val) =>
                  props.setFilters({
                    [col.dataIndex]: val,
                  })
                }
                onDelete={() => {
                  props.deleteFilter(col.dataIndex);
                }}
              />
            </Card.Grid>
          ))}

        {dimensionColumns
          .filter(
            (col) => col.dataIndex !== "date" && !props.filters[col.dataIndex]
          )
          .map((col) => (
            <Card.Grid
              key={col.dataIndex}
              style={{
                textAlign: "center",
                maxWidth: "150px",
                width: "auto",
                padding: "0px 0px",
              }}
            >
              <DashboardFilterSelect
                key={col.dataIndex}
                index={col.dataIndex}
                filter={
                  props.filters[col.dataIndex] || {
                    value: [],
                    type: "include",
                  }
                }
                label={col.title}
                disabled={props.isLoading}
                isVisible={visibleFilters.includes(col.dataIndex)}
                onChange={(val) =>
                  props.setFilters({
                    [col.dataIndex]: val,
                  })
                }
                onDelete={() => {
                  props.deleteFilter(col.dataIndex);
                }}
              />
            </Card.Grid>
          ))}
      </Card>
    </>
  );
};
