import { Form, Input, message, Modal, Switch } from "antd";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { handleApiError } from "../utils/ApiErrorHandler";
import { addUser } from "./api";

interface AddUserModalProps {
  isOpen: boolean;
  onCancel: () => void;
}

const AddUserModal: React.FC<AddUserModalProps> = (props) => {
  const [email, setEmail] = useState("");
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const addUserMutation = useMutation({
    mutationFn: addUser,
    onError: (error) => {
      error && handleApiError(error);
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries("users");
      message.success("Success");
    },
  });

  const updateName = useMemo(
    () =>
      debounce((name) => {
        form.setFieldValue("name", name);
        form.validateFields(["name"]);
      }, 300),
    []
  );

  useEffect(() => {
    let newName: string | string[] = email.split("@")[0].split(".");
    try {
      newName = `${newName[0][0].toUpperCase() + newName[0].slice(1)} ${
        newName[1][0].toUpperCase() + newName[1].slice(1)
      }`;
      updateName(newName);
    } catch (error) {
      if (!(error instanceof TypeError)) {
        console.log(error);
      }
    }
  }, [email]);

  return (
    <Modal
      title="Add user"
      onCancel={props.onCancel}
      open={props.isOpen}
      destroyOnClose
      okButtonProps={{
        htmlType: "submit",
      }}
      onOk={() => {
        form.validateFields().then((values) => {
          console.log(values);
          addUserMutation.mutate(values);
          props.onCancel();
        });
      }}
    >
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        onFinish={(values) => console.log(values)}
        form={form}
        initialValues={{
          email: "",
          name: "",
          is_admin: false,
        }}
      >
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            { required: true, message: "Please provide user e-mail" },
            {
              type: "email",
              message: "The input is not valid e-mail",
            },
          ]}
        >
          <Input onChange={(e) => setEmail(e.target.value)} />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please provide user name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="is_admin" label="Admin">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddUserModal;
