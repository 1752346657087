import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Table, Tag } from "antd";
import { useContext, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { MainContext } from "../Main";
import { Page } from "../utils/Page";
import { PageTitle } from "../utils/PageTitle";
import AddUserModal from "./AddUserModal";
import UserPermissionsModal from "./UserPermissionsModal";
import { fetchUsers } from "./api";
import { Permission, User } from "./types";

const Users: React.FC = () => {
  const [selectedUser, setSelectedUser] = useState<User>();
  const { user } = useContext(MainContext);
  const queryClient = useQueryClient();
  const users = useQuery("users", fetchUsers, {
    onError: (error) => console.log(error),
  });
  const [isFormVisible, setIsFormVisible] = useState(false);

  return (
    <Page>
      <PageTitle
        title="Users"
        breadcrumbs={[
          {
            label: "Users",
          },
        ]}
        extra={
          <Button
            onClick={() => setIsFormVisible(true)}
            type="primary"
            icon={<PlusOutlined />}
          >
            Add user
          </Button>
        }
      />
      <Table
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          position: ["bottomRight", "topRight"],
        }}
        scroll={{ x: true }}
        bordered
        loading={users.isLoading}
        dataSource={users.data}
        rowKey="id"
        columns={[
          {
            key: "id",
            title: "ID",
            dataIndex: "id",
          },
          {
            key: "name",
            title: "Name",
            dataIndex: "name",
          },
          {
            key: "id",
            title: "E-mail",
            dataIndex: "email",
          },
          {
            key: "role",
            title: "Role",
            dataIndex: "is_admin",
            render: (value) => (
              <Tag color={value ? "green" : "orange"}>
                {value ? "Admin" : "User"}
              </Tag>
            ),
          },
          {
            key: "permissions",
            title: "Permissions",
            children: [
              {
                key: "apps",
                title: "Apps",
                dataIndex: "permissions",
                render: (value: Permission[]) =>
                  value
                    .filter((row) => row.scope === "app" && row.app)
                    .map((row) => (
                      <Tag key={row.entity_name}>{row.entity_name}</Tag>
                    )),
              },
              {
                key: "teams",
                title: "Teams",
                dataIndex: "permissions",
                render: (value: Permission[]) =>
                  value
                    .filter((row) => row.scope === "team")
                    .map((row) => (
                      <Tag key={row.entity_name}>{row.entity}</Tag>
                    )),
              },
              {
                key: "companies",
                title: "Companies",
                dataIndex: "permissions",
                render: (value: Permission[]) =>
                  value
                    .filter((row) => row.scope === "company")
                    .map((row) => (
                      <Tag key={row.entity_name}>{row.entity}</Tag>
                    )),
              },
              {
                key: "actions",
                title: "",
                render: (record) => (
                  <Button
                    onClick={() => {
                      setSelectedUser(record);
                    }}
                    size="small"
                    icon={<EditOutlined />}
                  >
                    Edit
                  </Button>
                ),
              },
            ],
          },
        ]}
      />
      {selectedUser && user && (
        <UserPermissionsModal
          onOk={() => setSelectedUser(undefined)}
          onCancel={() => setSelectedUser(undefined)}
          selectedUser={selectedUser}
          activeUser={user}
          isOpen={!!selectedUser}
          onUserPermissionChange={(userEdited) => {
            users.data &&
              queryClient.setQueryData(
                "users",
                users.data.map((row) =>
                  row.id === userEdited.id ? userEdited : row
                )
              );
            setSelectedUser(userEdited);
          }}
        />
      )}
      <AddUserModal
        isOpen={isFormVisible}
        onCancel={() => setIsFormVisible(false)}
      />
    </Page>
  );
};

export default Users;
