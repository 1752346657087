import { AutoComplete, Form, Input } from "antd";
import { useQuery } from "react-query";
import { fetchAutocompleteValues } from "../utils/api";
import { FormProps, ModalForm } from "../utils/ModalForm";

export const AppForm: React.FC<FormProps> = (props) => {
  const appCategorySuggestions = useQuery(
    ["app_category", "autocomplete"],
    () => fetchAutocompleteValues("app_category"),
    { enabled: false }
  );
  const teamSuggestions = useQuery(
    ["team", "autocomplete"],
    () => fetchAutocompleteValues("team"),
    { enabled: false }
  );
  const publisherSuggestions = useQuery(
    ["publisher", "autocomplete"],
    () => fetchAutocompleteValues("publisher"),
    { enabled: false }
  );
  const atomSuggestions = useQuery(
    ["atom", "autocomplete"],
    () => fetchAutocompleteValues("atom"),
    { enabled: false }
  );
  const bankAccountSuggestion = useQuery(
    ["bank_accounts", "autocomplete"],
    () => fetchAutocompleteValues("bank_account"),
    { enabled: false }
  );

  return (
    <ModalForm {...props}>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please provide name of app" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Category"
        name="category"
        rules={[{ required: true, message: "Please provide category of app" }]}
      >
        <AutoComplete
          options={appCategorySuggestions.data}
          onDropdownVisibleChange={() => {
            appCategorySuggestions.data === undefined &&
              appCategorySuggestions.refetch();
          }}
        />
      </Form.Item>
      <Form.Item label="Facebook Payout" name="facebook_payout">
        <AutoComplete
          options={bankAccountSuggestion.data}
          onDropdownVisibleChange={() =>
            bankAccountSuggestion.data === undefined &&
            bankAccountSuggestion.refetch()
          }
        />
      </Form.Item>
      <Form.Item label="RevShare" name="revshare">
        <Input />
      </Form.Item>
      <Form.Item label="Atom" name="atom">
        <AutoComplete
          options={atomSuggestions.data}
          onDropdownVisibleChange={() =>
            atomSuggestions.data === undefined && atomSuggestions.refetch()
          }
        />
      </Form.Item>
      <Form.Item label="Team" name="team">
        <AutoComplete
          options={teamSuggestions.data}
          onDropdownVisibleChange={() =>
            teamSuggestions.data === undefined && teamSuggestions.refetch()
          }
        />
      </Form.Item>
      <Form.Item label="Publisher" name="publisher">
        <AutoComplete
          options={publisherSuggestions.data}
          onDropdownVisibleChange={() =>
            publisherSuggestions.data === undefined &&
            publisherSuggestions.refetch()
          }
        />
      </Form.Item>
    </ModalForm>
  );
};
