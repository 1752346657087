import { Spin } from "antd";

interface SpinWrapperProps {
  spinVisible: boolean;
  size?: "large" | "small" | "default";
  children: any;
  hideTip?: boolean;
}

export const SpinWrapper: React.FC<SpinWrapperProps> = (props) => {
  return props.spinVisible ? (
    <Spin
      size={props.size || "default"}
      tip={props.hideTip ? undefined : "Loading..."}
    >
      {props.children}
    </Spin>
  ) : (
    <>{props.children}</>
  );
};
