import { InfoCircleOutlined } from "@ant-design/icons";
import { Table, Tooltip, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { useMemo } from "react";
import { metricsWithoutDollar } from "../Dashboard";
import { metricColumns } from "./columns";
import { EstimationRow, EstimationsResponse } from "./types";

const { Text } = Typography;

interface EstimationsTableProps {
  data?: EstimationsResponse;
  isLoading: boolean;
  estimationDateStart?: string;
}

export const EstimationsTable: React.FC<EstimationsTableProps> = (props) => {
  const digits = useMemo(
    () => (metricsWithoutDollar.includes(props.data?.metric || "") ? 0 : 2),
    [props.data?.metric]
  );

  const columns: ColumnsType<EstimationRow> = [
    {
      dataIndex: "date",
      title: "Date",
      sorter: {
        compare: (a, b) => a.date.toString().localeCompare(b.date.toString()),
        multiple: 0,
      },
      render: (val) => {
        return dayjs(val).format("YYYY-MM-DD");
      },
    },
    {
      dataIndex: "real_value",
      title: "Real Data",
      align: "right",
      sorter: {
        compare: (a, b) => (a.real_value || 0) - (b.real_value || 0),
        multiple: 1,
      },
      render: (value: number) =>
        value
          ? value.toLocaleString("en-US", {
              minimumFractionDigits: digits,
              maximumFractionDigits: digits,
            })
          : undefined,
    },
    {
      dataIndex: "predicted_value",
      title: "Prediction",
      align: "right",
      sorter: {
        compare: (a, b) => (a.predicted_value || 0) - (b.predicted_value || 0),
        multiple: 2,
      },
      render: (value: number) =>
        value
          ? value.toLocaleString("en-US", {
              minimumFractionDigits: digits,
              maximumFractionDigits: digits,
            })
          : undefined,
    },
    {
      dataIndex: props.data?.metric || "",
      title:
        metricColumns.find((col) => col.dataIndex === props.data?.metric)
          ?.title || "",
      sorter: {
        compare: (a, b) => {
          const valA =
            dayjs(a.date) >= dayjs(props.estimationDateStart)
              ? a.predicted_value
              : a.real_value;
          const valB =
            dayjs(b.date) >= dayjs(props.estimationDateStart)
              ? b.predicted_value
              : b.real_value;
          return (valA || 0) - (valB || 0);
        },
        multiple: 3,
      },
      render: (value, record) => {
        if (dayjs(record.date) >= dayjs(props.estimationDateStart)) {
          return (
            <Text italic type="secondary">
              {(record.predicted_value || "").toLocaleString("en-US", {
                minimumFractionDigits: digits,
                maximumFractionDigits: digits,
              })}{" "}
              <Tooltip title="Estimated">
                <InfoCircleOutlined />
              </Tooltip>
            </Text>
          );
        } else {
          return (record.real_value || "").toLocaleString("en-US", {
            minimumFractionDigits: digits,
            maximumFractionDigits: digits,
          });
        }
      },
    },
  ];
  return (
    <Table
      rowKey={"date"}
      columns={
        props.data?.back_in_time
          ? [columns[0], columns[1], columns[2]]
          : [columns[0], columns[3]]
      }
      loading={props.isLoading}
      dataSource={props.data?.estimations || []}
      size="small"
      pagination={{
        defaultPageSize: 20,
        showSizeChanger: true,
        position: ["bottomRight", "topRight"],
      }}
    />
  );
};
