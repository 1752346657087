import { Alert, Form, Input, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useMemo, useState } from "react";
import { FormProps, ModalForm } from "../utils/ModalForm";
import { Provider } from "./types";

interface AccountFormProps extends FormProps {
  providers: Provider[];
}

export const AccountForm: React.FC<AccountFormProps> = (props) => {
  const [selectedProviderId, setSelectedProviderId] = useState("");

  useEffect(() => {
    if (!props.isOpen) {
      setSelectedProviderId("");
    }
  }, [props.isOpen]);

  const providerFields = useMemo(() => {
    if (selectedProviderId === "") {
      return [];
    } else {
      return props.providers.filter((x) => x.id === selectedProviderId)[0]
        .fields;
    }
  }, [props.providers, selectedProviderId]);

  const selectedProvider = useMemo(() => {
    if (selectedProviderId === "") return undefined;
    return props.providers.find((x) => x.id === selectedProviderId);
  }, [props.providers, selectedProviderId]);

  return (
    <ModalForm {...props}>
      <Form.Item
        label="Provider"
        name="provider_id"
        rules={[{ required: true, message: "Please select provider" }]}
      >
        <Select
          showSearch
          onChange={(value: string) => {
            setSelectedProviderId(value);
          }}
          options={props.providers.map((provider) => ({
            value: provider.id,
            label: provider.name,
          }))}
        />
      </Form.Item>
      {selectedProviderId !== "" && (
        <>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              { required: true, message: "Please provide name of account" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Bank Account"
            name="bank_account"
            rules={[{ required: true, message: "Please provide bank account" }]}
          >
            <Input />
          </Form.Item>
          {selectedProvider && selectedProvider.description && (
            <Form.Item label="Description">
              <Alert
                type="info"
                closable={false}
                message={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedProvider.description,
                    }}
                  />
                }
              />
            </Form.Item>
          )}
          {providerFields.map((field) => (
            <Form.Item
              key={field.key}
              label={field.name}
              name={["credentials", field.key]}
              rules={[
                {
                  required: true,
                  message: `Please provide ${field.name}`,
                },
              ]}
            >
              {field.type === "text" ? (
                <Input placeholder={field.placeholder} />
              ) : (
                <TextArea placeholder={field.placeholder} />
              )}
            </Form.Item>
          ))}
        </>
      )}
    </ModalForm>
  );
};
