export const BoomBitLogo = () => (
  <svg
    width="1em"
    height="1em"
    version="1.1"
    viewBox="0 0 23.812 23.813"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    style={{ verticalAlign: "-0.125em" }}
  >
    <g>
      <rect x="0" y="0" width="23.812" height="23.812" fill="#c73c34" />
      <g fil="#000">
        <path
          d="m8.6779 7.8967h3.0734q0.53552 0 1.071 0.081492 0.54716 0.06985 0.9779 0.30268 0.43074 0.22119 0.6985 0.62865 0.26776 0.40746 0.26776 1.0827 0 0.6985-0.39582 1.1642-0.38418 0.45403-1.0245 0.65194v0.02328q0.40746 0.05821 0.73343 0.23283 0.33761 0.16298 0.57044 0.43074 0.24448 0.25612 0.37253 0.60537t0.12806 0.74507q0 0.65194-0.2794 1.0943-0.2794 0.43074-0.72178 0.6985-0.44238 0.26776-1.0012 0.38418-0.54716 0.11642-1.0943 0.11642h-3.3761zm1.8161 3.2946h1.3155q0.20955 0 0.40746-0.04657 0.20955-0.04657 0.37254-0.15134 0.16298-0.10478 0.25612-0.2794 0.10478-0.17462 0.10478-0.4191 0-0.25612-0.11642-0.4191-0.10478-0.17463-0.2794-0.26776-0.17463-0.093134-0.39582-0.12806-0.22119-0.046567-0.43074-0.046567h-1.234zm0 3.411h1.6298q0.20955 0 0.4191-0.04657 0.22119-0.04657 0.39582-0.16298 0.17462-0.11642 0.2794-0.30268 0.11642-0.18627 0.11642-0.45403 0-0.29104-0.15134-0.46567-0.1397-0.18627-0.36089-0.2794-0.22119-0.09313-0.47731-0.12806-0.25612-0.03492-0.47731-0.03492h-1.3737z"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);
